function vodClip() {
    return {
        restrict: "E",
        scope: {
            video : "=",
            hasStatusBar : "=",     // 시청상태바 표기여부
            hasFanSubscribe : "=",  // 팬, 구독 표기여부
            isAdvertisement : "@",  // 광고여부
            showActionSheet : "&",  // 액션시트 선택시 부모 콜백함수
        },
        template : require('../templates/video.template.clip.html'),
        link : function(scope, event, attrs){
            scope.bShowStatusBar = (attrs.hasStatusBar === undefined)? false : scope.$eval(attrs.hasStatusBar);
            scope.bShowFanSubscribe = (attrs.hasFanSubscribe === undefined)? false : scope.$eval(attrs.hasFanSubscribe);
            scope.bAdvertisement = (attrs.isAdvertisement === undefined)? false : scope.$eval(attrs.isAdvertisement);

            scope.showParentActionSheet = function($event){
                $event.stopPropagation();
                $('.icon-menu').hide();
                scope.showActionSheet();
            }
        },
        controller : ["$scope", "$rootScope", "Util", "FavoriteService", "ToastMessage", "$state", "$sessionStorage", "LoginManager", "gtext", "SearchService", "CookieFactory", function($scope, $rootScope, Util, FavoriteService, ToastMessage, $state, $sessionStorage, LoginManager, gtext, SearchService, CookieFactory){
            $scope.szVodDefaultImageURL = RES_AFREECATV + "/images/mobile/common/img_default.jpg";
            $scope.szBjDefaultImageURL = RES_AFREECATV + "/images/afmain/img_thumb_profile.gif";

            $scope.init = function(oVideo){
                if($scope.bAdvertisement == false){
                    if (oVideo.type == 'LIVE') {
                        oVideo.bj_img = Util.getBjThumbnail(oVideo.user_id);
                        oVideo.thumb = LIVEIMG_AFREECATV + '/l/'+oVideo.broad_no;
                    }
                    else {
                        oVideo.bj_img = Util.getBjThumbnail(oVideo.user_id);
                        oVideo.total_duration = parseInt(oVideo.total_duration);
                        oVideo.reg_time = Util.regDateConvert(oVideo.reg_timestamp);
                        oVideo.view_rate = Math.round((oVideo.last_view_duration / oVideo.total_duration) * 100) + "%";
                    }

                    if($rootScope.oSelectedSubmenu.id == 'category') {
                        oVideo.original_user_id = oVideo.original_bj; //검색 api
                    }
                }
            }
            
            // 플레이어 가기
            $scope.goPlayer = function(oVideo){
                if (oVideo.type == 'LIVE') {
                    oVideo.scheme = 'afreeca://player/live?broad_no='+oVideo.broad_no+'&user_id='+oVideo.user_id+'&channel=LIVE';
                    Util.action(oVideo.scheme);
                }
                else {
                    if(oVideo.file_type =="CATCH"){
                        CookieFactory.set('listPreviousUrl', window.location.href, 1);
                    }
                    $rootScope.action(oVideo.scheme);
                    $scope.InflowLogEvent(oVideo);
                }
            }

            // 유입경로 처리
            $scope.InflowLogEvent = function(oVideo){
                // VOD유입경로 설정
                var arrInflowPath = ['etc'];
                var nTitleNo = oVideo.title_no;
                var szBjId = '';
                // BJ ID가 있을 경우에는 대입해줌
                if(oVideo.user_id){
                    szBjId = oVideo.user_id;
                }

                // 메뉴 ID값 가져옴
                var szSubMenuId = $rootScope.oSelectedSubmenu.id;
                switch(szSubMenuId){
                    case 'hot': // 핫이슈
                        arrInflowPath = [];
                        arrInflowPath['path1'] = 'vod';
                        arrInflowPath['path2'] = 'hot';
                        arrInflowPath['path3'] = oVideo.filter;
                        arrInflowPath['vod_idx'] = oVideo.idx;
                        arrInflowPath['list_type'] = oVideo.data_type;

                        // VOD 유입경로 전송
                        Util.directVodInflowPath(szBjId, nTitleNo, arrInflowPath);
                        break;
                    case 'library': // 기록
                        var oTab = $sessionStorage.oVodTab;
                        if(oTab != undefined){
                            var szTabId = oTab.id;
                            // 기록 나누기
                            switch(szTabId){
                                case 'later':
                                    arrInflowPath = ['vod', 'afterward'];
                                    break;
                                case 'recent':
                                    arrInflowPath = ['vod', 'recent'];
                                    break;
                                case 'up':
                                    arrInflowPath = ['vod', 'up'];
                                    break;
                                default:
                                    arrInflowPath = ['etc'];
                                    break;
                            }
                        }

                        // VOD 유입경로 전송
                        Util.sendInflowLog('VOD', szBjId, nTitleNo, arrInflowPath);
                        break;
                    case 'category': // 카테고리

                        var aInflowPath = $sessionStorage.category_inflowpath;
                        arrInflowPath = {path1 :'vod', path2:'cate'};
                        let i=3;
                        aInflowPath.forEach(function(element){
                            arrInflowPath['path'+i] = element;
                            i++;
                        })
                       
                        // VOD 유입경로 전송
                        Util.directVodInflowPath(szBjId, nTitleNo, arrInflowPath);
                        break;
                }
            }

            // 방송국 가기
            $scope.goBjStation = function($event, szUserId){
                $event.stopPropagation();
                $('.icon-menu').hide();
                Util.goStation(szUserId);
            }

            // 광고주페이지 or 앱 설치 화면으로 이동.
            $scope.goAction = function(url){
                Util.popup(url);
            }

            $('.content_wrap').off('click').on('click', function(e) {
                // 닉네임 이외의 영역 클릭 시 닫기
                if($(e.target).closest('.bj_warp').length == 0) {
                    $('.icon-menu').hide();
                }
            });

            // 닉네임 클릭
            $scope.clickNick = function(userId, target, event) {
                event.stopPropagation();

                let $iconMenu = null;

                // 유저, 원저작자 닉네임 ICT 열기 닫기
                switch(target) {
                    case 'user':
                        $iconMenu = $(event.target).closest('.bj_warp').siblings('.userIconMenu');
                        break;
                    case 'orgin':
                        $iconMenu = $(event.target).closest('.bj_warp').siblings('.orgUserIconMenu');
                        break;
                }

                const display = $iconMenu.css("display");

                $('.icon-menu').hide();

                if(display != 'none') return; //닉네임 메뉴가 열려있는 상태이면 닫고 끝냄
 
                $iconMenu.show();

                const login = $rootScope.member;

                if(login) {
                    $scope.bMyVod = (userId == login.user_id) ? true : false; //본인 VOD 여부
                    FavoriteService.check(userId).then(function(bFavorite) {
                        $scope.bFavorite = bFavorite;
                        $scope.$digest();
                    });
                }
            }

            // 쪽지
            $scope.clickNote = function(userId) {
                $('.icon-menu').hide();

                if(!$rootScope.member) {
                    if (confirm(gtext("로그인이 필요합니다.\n로그인 하시겠습니까?"))) {
                        $sessionStorage.szNoteUserId = userId;
                        $rootScope.login();
                    }
                    return;
                }

                $scope.sendNote(userId);
            }

            // 쪽지 보내기
            $scope.sendNote = function(userId) {
                delete $sessionStorage.szNoteUserId;

                const url = $state.href('note.write2', {id: userId});
                window.open(url,'_blank');
            }

            // 즐겨찾기
            $scope.clickFavorite = function(userId) {
                $('.icon-menu').hide();

                if(!$rootScope.member) {
                    if (confirm(gtext("즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?"))) {
                        $sessionStorage.szFavUserId = userId;
                        $rootScope.login();
                    }
                    return;
                }

                ($scope.bFavorite) ? $scope.delFavorite(userId) : $scope.addFavorite(userId);
            }

            // 즐겨찾기 삭제
            $scope.delFavorite = function(userId) {
                FavoriteService.delete(userId).then(function(data){
                    ToastMessage(data.message);
                })
                .catch(function (response) {
                    ToastMessage(response.data.message);
                });
            }

            // 즐겨찾기 추가
            $scope.addFavorite = function(userId) {
                delete $sessionStorage.szFavUserId;

                FavoriteService.add(userId).then(function(data){
                    ToastMessage(data.message);
                })
                .catch(function(response) {
                    ToastMessage(response.data.message);
                });
            }

            // 유저 검색
            $scope.searchUser = function(userId) {
                $('.icon-menu').hide();

                const uId = ($rootScope.member) ? $rootScope.member.user_id : null;

                SearchService.checkWord({
                    d: userId,
                    uid: uId,
                    stype: 'ui', //닉네임 ICT 검색
                    acttype: 'vod'
                }).then(function (data) {
                    if (data.isstop === '1') {
                        ToastMessage(data.stopword + gtext('은(는) 검색을 제한한 단어이므로 검색하실 수 없습니다.'));
                    }
                    else {
                        $state.go('search', {query: userId});
                    }
                }).catch(function (errors) {
                    ToastMessage(gtext('검색시스템에 문제가 발생했습니다.'));
                });
            }

            // 로그인 상태 변경시
            $scope.$on("$loginChange", function(){
                const member = $rootScope.member;

                if(!member) return;

                // 즐겨찾기 세션 존재하면 즐겨찾기 추가
                if($sessionStorage.szFavUserId){
                    if($sessionStorage.szFavUserId == member.user_id) {
                        delete $sessionStorage.szNoteUserId;
                        ToastMessage(gtext('본인은 즐겨찾기 할 수 없습니다.'));
                        return;
                    }

                    $scope.addFavorite($sessionStorage.szFavUserId);
                }
                // 쪽지 세션 존재하면 쪽지 보내기
                if($sessionStorage.szNoteUserId) {
                    if($sessionStorage.szNoteUserId == member.user_id) {
                        delete $sessionStorage.szNoteUserId;
                        ToastMessage(gtext('본인에게는 쪽지를 보낼 수 없습니다.'));
                        return;
                    }

                    $scope.sendNote($sessionStorage.szNoteUserId);
                }
            });
        }]
    }
}

module.exports = vodClip;