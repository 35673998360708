var toastr = require('toastr');
FavoriteListController.$inject = ['$scope', '$rootScope', '$state', 'FavoriteService', 'Util', 'LoginManager', 'gtext', 'debounce'];

function FavoriteListController($scope, $rootScope, $state, FavoriteService, Util, LoginManager, gtext, debounce) {

	$rootScope.title = '즐겨찾기';
	// order정보
	$scope.aOrderByColumns = [{
			field: 'favorite_no',
			name: '최신등록순',
			orderBy: 'DESC'
		},
		{
			field: 'user_nick',
			name: '닉네임순',
			orderBy: 'ASC'
		},
		{
			field: 'recent_broad_time',
			name: '최신방송순',
			orderBy: 'DESC'
		}
	];
	$scope.selectOrder = $scope.aOrderByColumns[0];
	var nRowsPerPage = 20;
	var bCompleted = true;
	var contents = [];
	$scope.nCurrentPage = 1;
	$scope.bHasMore = false;
	$scope.szOrderByType = 'DESC';
	$scope.bRemainPush = true;
	$scope.szIsSetPushALL = '';

	var myEfficientFn = debounce(50, function () {
		if ($scope.bHasMore) {
			bCompleted = false;
			$scope.nCurrentPage++
				$.merge($scope.contents, getPageContents());
			syncPusFlag();
			$scope.bHasMore = getHasMore();
			$scope.$digest();
			bCompleted = true;
		}
	});

	// 더보기
	Util.lastItem(myEfficientFn);

	$scope.getList = function () {
		$scope.oGroups = {};

		// 리스트가져오기 success 함수
		FavoriteService.getItems({

		}).then(function (response) {
			var groups = response.data.groups[0];
			contents = groups.contents;
			sort();
			$scope.contents = getPageContents();
			$scope.bHasMore = getHasMore();
			$scope.$digest();

		}).then(function (oRes) {
			FavoriteService.bCompleted = true;
		});
	};

	function getHasMore() {
		return contents.length > (nRowsPerPage * $scope.nCurrentPage);
	}

	function getPageContents() {
		var start = nRowsPerPage * ($scope.nCurrentPage - 1);
		return contents.slice(start, start + nRowsPerPage);
	}

	function sort() {
		contents.sort(sortFn($scope.selectOrder.field, $scope.szOrderByType));
	}

	function scopeSort() {
		$scope.contents.sort(sortFn($scope.selectOrder.field, $scope.szOrderByType));
		$scope.$digest();
	}

	function sortFn(sortField, szOrderByType) {
		var field = sortField;
		var orderBy = szOrderByType;
		return function (a, b) {
			if (a.is_pin - b.is_pin == 0) {
				if (orderBy == 'DESC') {
					if (Number.isInteger(b[field])) {
						return b[field] - a[field];
					} else {
						if (a[field] < b[field]) {
							return 1;
						} else if (a[field] > b[field]) {
							return -1;
						} else {
							return 0;
						}
					}
				} else {
					if (Number.isInteger(b[field])) {
						return a[field] - b[field];
					} else {
						if (a[field] > b[field]) {
							return 1;
						} else if (a[field] < b[field]) {
							return -1;
						} else {
							return 0;
						}
					}
				}
			} else {
				return b.is_pin - a.is_pin;
			}
		}
	}

	// 알림 전체 ON/OFF 동기화
	function syncPusFlag() {
		$scope.contents.forEach(function(element){
			if ($scope.szIsSetPushALL == 'ALL_ON') {
				element.is_push = 1;
			} else if ($scope.szIsSetPushALL == 'ALL_OFF') {
				element.is_push = 0;
			}
		});
	}

	$scope.setPin = function (oItem) {
		if (loginCheck()) {
			FavoriteService.favoritePin({
				favorite_no: oItem.favorite_no,
				pin: 1
			}).then(function () {
				oItem.is_pin = 1;
				scopeSort();
				toastr.info(gtext('상단고정 완료되었습니다'));
			})
		}
	}

	$scope.unsetPin = function (oItem) {
		if (loginCheck()) {
			FavoriteService.favoritePin({
				favorite_no: oItem.favorite_no,
				pin: 0
			}).then(function () {
				oItem.is_pin = 0;
				scopeSort();
				toastr.info(gtext('상단고정 해제 되었습니다'));
			})
		}
	}

	$scope.deleteFavoriteHandler = function (szUserId, index, oLogData) {
		if (loginCheck()) {
			FavoriteService.delete(szUserId, oLogData).then(function () {
				toastr.success(gtext("즐겨찾기가 삭제되었습니다."));
				$scope.contents.splice(index, 1);
				$scope.$digest();
			})
		}
	}

	$scope.live = function (user_id, broad_no) {
		$state.go('player', {
			broad: (user_id + '/' + broad_no)
		});
	}

	//정렬 탭 클릭
	$scope.clickOrder = function (oOrder) {
		if ($scope.selectOrder == oOrder) {
			$scope.szOrderByType = $scope.szOrderByType == 'DESC' ? 'ASC' : 'DESC';
		} else {
			$scope.selectOrder = oOrder;
			$scope.szOrderByType = $scope.selectOrder.orderBy;
		}
		sort();
		$scope.nCurrentPage = 1;
		$scope.contents = getPageContents();
		$scope.bHasMore = getHasMore();
	};

	// 개별알림 SWITCH
	$scope.setPushFlag = function () {
		if (loginCheck()) {
			let oItem = this.oItem;
			let pushFlag = this.oItem.is_push ? 0 : 1
	
			FavoriteService.updatePush({
				id: this.oItem.user_id,
				push: pushFlag
			}).then(function (response) {
				if (response.result === 1) {
					oItem.is_push = pushFlag;
				}
				if (pushFlag) {
					toastr.info(gtext('%s님에게 알림을 받습니다.',oItem.user_nick));
				} else {
					toastr.info(gtext('%s님에게 알림을 받지 않습니다.',oItem.user_nick));
				}
				$scope.szIsSetPushALL = '';
				$scope.checkSetPush();
				$scope.$digest();
			}).catch(function (response) {
				$scope.$digest();
			});
		}
	}

	// 전체알림 SWITCH
	$scope.setPushAllFlag = function () {
		if (loginCheck()) {
			let pushAllFlag = $scope.bRemainPush ? 1 : 0;
			let confirmMessage;
	
			if (pushAllFlag) {
				confirmMessage = gtext('모든 즐겨찾기 BJ에게 알림을 받습니다.');
			} else {
				confirmMessage = gtext('모든 즐겨찾기 BJ에게 알림을 받지 않습니다.'); 
			}
	
			if (confirm(confirmMessage)) {
				FavoriteService.updateAllPush({
					is_push: pushAllFlag
				}).then(function (response) {
					if (response.result === 1) {
						$scope.bRemainPush = !$scope.bRemainPush;
						$scope.szIsSetPushALL = pushAllFlag === 1 ? 'ALL_ON' : 'ALL_OFF';
						syncPusFlag();
					}
					$scope.$digest();
				}).catch(function (response) {
					$scope.$digest();
				});
			}
		}
	}

	// 알림 미설정 잔여확인
	$scope.checkSetPush = function () {
        FavoriteService.checkSetPush(LoginManager.getUserId())
        .then(function (response) {
			$scope.bRemainPush = response.data;
            $scope.$digest();
        }).catch(function (response) {
            $scope.$digest();
        });
	}

	function loginCheck() {
		if (LoginManager.isLogin()) {
			return true;
		} else {
			toastr.error("로그인이 되어 있지 않습니다.");
			setTimeout(function () {
				$state.go("home");
			}, 500);
		}
	}

	if (loginCheck()) {
		$scope.getList();
		$scope.checkSetPush();
	}

	
}

module.exports = FavoriteListController;