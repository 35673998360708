var Swiper = require("swiper/dist/js/swiper.min");
NoteStoreViewController.$inject = ['$scope', '$window', '$rootScope', '$state', '$stateParams', '$http', '$timeout', 'Util', 'LoginManager', 'NoteService', '$sce', 'gtext']

function NoteStoreViewController($scope, $window, $rootScope, $state, $stateParams, $http, $timeout, Util, LoginManager, NoteService, $sce, gtext) {
    //초기화
    $scope.note = {
        read_date: 0
    };

    var oDate = new Date();
    $scope.date = oDate.getFullYear() + '-' + ((oDate.getMonth()+1).addZero()) + '-' + oDate.getDate().addZero() + ' ' + oDate.getHours().addZero() + ":" + oDate.getMinutes().addZero() + ":" + oDate.getSeconds().addZero();

    $scope.byteConvert = function (bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        bytes = Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        return bytes;
    };

    var mySwiper = null;
    $scope.bLoginAdmin = false;
    $scope.szStationUrl = "";
    $scope.subscribeType = false;

    $rootScope.headerClassRemove();

    $scope.layers = function (target, index) {
        if (mySwiper == null) {
            mySwiper = new Swiper('.swiper-container', {
                navigation: {
                    nextEl: '.viewer-next',
                    prevEl: '.viewer-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                }
            });
        }
        $scope.layerOpen = true;
        mySwiper.slideTo(index);
    };
    $scope.layersClose = function (target) {
        $scope.layerOpen = false;
    };

    $rootScope.note_cate = (["1", "2"].indexOf($rootScope.note_cate) == -1) ? '1' : $rootScope.note_cate// 받은 쪽지:1, 보낸쪽지:2
    $scope.returnGo = function (szWork) {
        switch (szWork) {
            case 'delete':
                $state.go('note.store', {
                    deleteNo: $scope.note.no,
                });
                return;

            case 'write':
                $state.go('note.write', {
                    id: $scope.note.send_id
                });
                return;

            case 'reload':
                $state.go('note.store', {}, { reload: 'note.store' });
                return;

            case 'back':
                history.back();
                return;
        }
    }

    /**
     * 삭제하기
     */
    $scope.delete = function (szBeforeAction) {
        if (szBeforeAction != 'report') {
            if (!confirm(gtext('삭제하시겠습니까?'))) {
                $scope.isDisabled = false;
                return;
            }
            $scope.isDisabled = true;
        }
        if ($rootScope.note_cate == 1) {
            NoteService.receviceDelete({
                no: $scope.note.no
            }).then(function (response) {
                $scope.isDisabled = false;
                //$scope.returnGo("delete");
                $scope.returnGo("back");
            }).catch(function (response) {
                $scope.isDisabled = false;
            });
        } else {
            NoteService.sendDelete({
                no: $scope.note.no
            }).
                then(function (response) {
                    $scope.isDisabled = false;
                    //$scope.returnGo("delete");
                    $scope.returnGo("back");

                }).catch(function (response) {
                    $scope.isDisabled = false;
                });
        }

    }

    $scope.reply = function () {
        if ($rootScope.bBlock) {
            alert($rootScope.szBlockMsg);
            return false;
        }
        $rootScope.bNickClick = false;
        $state.go('note.write', {
			id: $scope.note.send_id
		});
    }

    /**
     * 신고하기
     */
    $scope.report = function () {
        $scope.isDisabled = true;
        aSpamNo = [];
        if (!confirm(gtext('해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)'))) {
            return;
        }
        aSpamNo.push($scope.note.no);
        NoteService.receviceReport({
            no: aSpamNo
        }).then(function (response) {
            $scope.isDisabled = false;
            //$scope.returnGo("delete");
            $scope.returnGo("back");
        }).catch(function (response) {
            $scope.isDisabled = false;
            alert(response.data.message);
            $scope.$digest();
        });

    }

    /**
     * 수신거부
     */
    $scope.reject = function () {
        $scope.isDisabled = true;
        NoteService.receviceReject({
            no: $scope.note.no
        }).then(function (response) {
            if (!confirm(gtext("수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?"))) {
                $scope.isDisabled = false;
                $scope.$digest();
                return;
            } else {
                $rootScope.setting();
                $scope.isDisabled = false;
                $scope.$digest();
            }
        }).catch(function (response) {
            $scope.isDisabled = false;
            alert(response.data.message);
            $scope.$digest();
        });
    }

    /**
     * 수신거부한 아이디가 보낸 쪽지 삭제
     */
    $scope.rejectIdNoteDelete = function () {
        NoteService.receviceRejectIdNoteDelete({
            no: $scope.note.no
        }).then(function (response) {
            $scope.isDisabled = false;
            if (response.result == 1) {
                $scope.returnGo("reload");
            }
        }).catch(function (response) {
            $scope.isDisabled = false;
            alert(response.data.messangBindHtmlUnsafege);
            $scope.$digest();
        });
    }


    $scope.getPhrases = function () {
        if ($rootScope.note_cate == '1') {
            return $sce.trustAsHtml(gtext('(받은 날짜로부터 <em>90</em>일 보관)'));
        } else {
            return $sce.trustAsHtml(gtext('(보낸 날짜로부터 <em>90</em>일 보관)'));
        }

    }
    // 받은 쪽지
    if ($rootScope.note_cate == '1') {
        NoteService.receviceView({
            no: $stateParams.no,
        }).then(function (response) {
            response.data.opponent_id = response.data.send_id;
            response.data.opponent_nick = response.data.send_nick;
            $scope.note = response.data;
            $scope.bLoginAdmin = $scope.note.bAdmin ? true : false;
            $scope.note.content = Util.eacapeBackslash($scope.note.content);
            $scope.$digest();
        }).catch(function (response) {
            alert(response.data.message);
            $scope.returnGo("delete");
        });
    } else {
        // 보낸 쪽지
        NoteService.sendView({
            no: $stateParams.no
        }).then(function (response) {
            response.data.opponent_id = response.data.recv_id;
            response.data.opponent_nick = response.data.recv_nick;
            $scope.note = response.data;
            $scope.bLoginAdmin = response.data.login_admin;
            $scope.note.content = Util.eacapeBackslash($scope.note.content);
            $scope.$digest();
        }).catch(function (response) {
            alert(response.data.message);
            $scope.returnGo("delete");
        });
    }

    goAosMove = function (url) {
        afreeca.link.browser(url);
    }

	goItemMove = function (url, scheme) {
        // 앱내의 웹뷰 일때
        if(afreeca.link.isWebview()) {
			var schemeUrl = scheme == '' ? ('afreeca://browser/station?url=' + encodeURIComponent(url)) : scheme;
			document.location.href = schemeUrl;
            return false;
		} else { // 모웹
			window.open(url, "_blank");
            return false;
        }
    }

    $scope.checkAtag = function (szContent, szNoteType, nSubType) {
        return Util.tagAchange('view', szContent, $scope.bLoginAdmin, szNoteType, nSubType);
    }

    $scope.nickClick = function ($event, bNickClick) {
		$event.stopPropagation();
		$event.preventDefault();
        $rootScope.bNickClick = !bNickClick;
		return $rootScope.bNickClick;
	}
	
	$scope.showIct = function () {
		return $rootScope.bNickClick ? '' : 'none';
	}

	// 방송국 가기
	$scope.goBjStation = function(szUserId){
        $rootScope.bNickClick = false;
        $scope.szStationUrl = BJ_AFREECATV + '/'+szUserId;
		if(Util.getOS() == 'android' && afreeca.link.isWebview() && Util.getOSVersion("6.3.0")['compare']) {
			goAosMove($scope.szStationUrl);
			return;
		}
        if(Util.getOS() == 'android' && afreeca.link.isWebview()) { // android go Station 함수 사용할 경우 본창으로 열리는 이슈가 있어 open 함수 추가
			window.open("afreeca://browser/station?url="+$scope.szStationUrl, "_blank");
		} else {
			afreeca.link.goStationLink($scope.szStationUrl);
		}
	}

	$scope.searchUser = function(szNoteCate , szSearchId) {
		$state.go('note.store', {deleteNo: null,searchWord: szSearchId, searchType: 2}, {reload: 'note.store'});
	}

	$scope.ictClose = function () {
        $rootScope.bNickClick = false;
    }

    	/**
	 * 인앱 결제 여부 확인
	 * @returns {Promise<boolean>}
	 */
	checkInAppPurchase = async () => {
		const szOS = Util.getOS(),
			oVersion = Util.getOSVersion(),
			bPlayStore = Util.isPlayStore();

		// 인앱 체크
		if (!afreeca.link.isWebview()) {
			return false;
		}

		// 허용 기기 확인
		const aAllowOS = ['android'];
		if (!aAllowOS.includes(szOS)) {
			return false;
		}

		// 플레이 스토어 확인
		if (!bPlayStore) return false;

		// 인앱 활성화 여부 조회
		const szVersion = oVersion.version;
		try {
			const oResponse = await ItemService.itemInfoApi({
				work: 'IN_APP',
				type: 'subscription',
				os: 'android',
				version: szVersion
			});
			if (oResponse.RESULT !== 1) {
				throw new Error(oResponse.MESSAGE);
			}

			// 인앱 활성화 여부 확인
			if (!oResponse.DATA) {
				return false;
			}
		} catch (error) {
			return false;
		}

		return true;
	};

	subscribeTypeCheck = async () => {
		await $http.post(
			'${ITEM_M_AFREECATV}/api/subscription/a/info', $.param(data || '')
		).then(function (response) {
			if (response.result == 1) {
				
				$scope.subscribeType = response.data.subscription_type == null || response.data.subscription_type ? false : true;
			} 
		}).catch(function (error) {
			$scope.subscribeType = false;
		})

		return $scope.subscribeType;
	};

	goPlayStore = (szScheme) => {
		subscribeTypeCheck();
		var isPlayStore = Util.isPlayStore();
		var isActiveInApp = checkInAppPurchase();
		szScheme += '&activeInApp=' + (isActiveInApp && isPlayStore);
		szScheme += '&isSubscribed=' + $scope.subscribeType;
		
		document.location.href = szScheme;
		return false;
	}
}
module.exports = NoteStoreViewController;

Number.prototype.addZero = function () {
    return ('0' + this).substr(-2);
}