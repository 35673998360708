Constant.$inject = ['$rootScope'];
function Constant($rootScope) {
        $rootScope.$const = {
            WWW_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//www.afreecatv.com'
            , RES_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//res.afreecatv.com'
            , AUTH_M_AFREECATV : 'https://auth.m.afreecatv.com'
            , MEMBER_M_AFREECATV : 'https://member.m.afreecatv.com'
            , EVENT_IMG_AFREECATV : ((document.location.protocol == 'https:') ? 'https:' : 'http:') + '//event.img.afreecatv.com'
            , BJ_AFREECATV : 'https://bj.afreecatv.com'            
            , M_AFREECATV : 'https://m.afreecatv.com'
            , PLAY_AFREECATV : 'https://play.afreecatv.com'
            , VOD_AFREECATV : 'https://vod.afreecatv.com'
            , SERVICE_ROOT_DOMAIN : 'afreecatv.com'
        };
};

module.exports = Constant; 