module.exports = {
	'TAG_VOD_LIST_COUNT' : '<span class="count">총 <em class="point" text="@count"></em>개</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">님이 자주 찾는 BJ의 VOD</span>',
	'TAG_VOD_RECENT_TITLE' : '<a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">님이 자주 찾는 BJ</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name" text="@nickname"></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span>님 VOD <span text="@count"></span>개</span>',
	
	'도전미션 일': '일',
	'도전미션 시간': '시간',
	'도전미션 분' : '분'
};