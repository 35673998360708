module.exports = {
	'홈': 'Trang chủ',
	'핫이슈': 'Chủ đề nóng',
	'나중에보기': 'Xem sau',
	'전체': 'Toàn bộ',
	'로그인': 'Đăng nhập',
	'최신순': 'Theo mới nhất',
	'참여인원순': 'Sắp xếp theo Số người xem',
	'즐겨찾기 추가': 'Thêm Yêu thích',
	'방송국 보러가기': 'Đi đến Blog',
	'나중에보기 등록': 'Đăng ký Xem sau',
	'목록편집': 'Chỉnh sửa mục lục',
	'전체삭제': 'Xóa toàn bộ',
	'재생한 VOD 삭제': 'Xóa VOD đã xem',
	'시청한 VOD': 'VOD đã xem',
	'다시보기': 'Xem lại',
	'로그인 후 나중에 보기에<br>등록한 VOD를 보실 수 있습니다.': 'Bạn có thể xem được các VOD đã đăng ký<br>Xem sau sau khi đăng nhập.',
	'나중에 보기에 등록한 VOD가 없습니다.': 'Bạn không có VOD được đăng ký Xem sau.',
	'영상을 시청할 수 없는 상황일 때,<br>나중에 보기 목록에 담아두고<br>언제든 원하는 시간에 손쉽게 찾아보세요!': 'Trong hoàn cảnh không thể xem video,<br>hãy lưu vào mục Xem sau<br>và xem chương trình bất cứ lúc nào bạn muốn một cách dễ dàng!',
	'나중에보기 삭제': 'Xóa Xem sau',
	'나중에 보기 서비스란?': 'Dịch vụ Xem sau là gì?',
	'나중에 보기에 등록되었습니다.': 'Đăng ký Xem sau thành công.',
	'나중에 보기가 삭제되었습니다.': 'Xem sau đã được xóa.',
	'라이브 방송을 시청할 수 없는 상황일 때': 'Trong hoàn cảnh không thể xem phát sóng trực tiếp',
	'목록에 담아두고 원하는 시간에 손쉽게 찾아 보세요!': 'hãy lưu vào mục lục và xem chương trình bất cứ lúc nào bạn muốn một cách dễ dàng!',
	'<span class="blue">VOD의 "나중에 보기" 메뉴</span>에서<br>등록 한 영상을 확인 하실 수 있습니다.': '<span class="blue">Bạn có thể xem video đã đăng ký</span>tại<br>menu “Xem sau” của VOD.',
	'보고 싶은 VOD나, 아직 보지 않은': 'VOD bạn muốn xem hay chưa xem',
	'VOD를 목록에 등록해두고 언제든 간편하게 찾아 보세요!': 'Hãy lưu VOD vào mục lục để có thể xem một cách đơn giản mọi lúc!',
	'다시 보지 않기': 'Không xem lại',
	'닫기': 'Đóng',
	'나중에 보기를 지원하지 않는 영상입니다.': 'Video này không hỗ trợ Xem sau.',
	'쪽지함': 'Hòm thư',
	'새 쪽지 <span>%s</span>개': 'Thư mới <span>%s</span>thư',
	'새 쪽지가 없습니다': 'Không có thư mới',
	'쪽지 보내기': 'Gửi thư',
	'쪽지설정': 'Cài đặt thư',
	'받은 쪽지함': 'Hòm thư đã nhận',
	'보낸 쪽지함': 'Hòm thư đã gửi',
	'쪽지 보관함': 'Thư lưu trữ',
	'전체 쪽지': 'Toàn bộ thư',
	'안읽은 쪽지': 'Thư chưa đọc',
	'보관한 쪽지': 'Thư được lưu trữ',
	'보관한 쪽지가 없습니다.': 'Không có thư được lưu trữ.',
	'보관한 쪽지는 영구 저장됩니다.(최대 1000개)': 'Thư được lưu trữ sẽ tồn tại vĩnh viễn. (Tối đa 1000 thư)',

	'보관': 'Lưu trữ',
	'신고': 'Báo cáo',
	'삭제': 'Xóa',
	'받은 쪽지가 없습니다.': 'Không có thư đã nhận.',
	'읽지않음': 'Chưa đọc',
	'읽음': 'Đã đọc',
	'더 보기': 'Xem thêm',
	'받은 쪽지': 'Nhận',
	'보낸 사람': 'Người gửi',
	'받은 시각': 'Thời gian nhận',
	'쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': 'Thư đã đọc sẽ được xóa tự động sau 90 ngày.',
	'답장': 'Trả lời',
	'수신거부': 'Chặn',
	'보낸 쪽지가 없습니다.': 'Không có thư đã gửi.',
	'보냄': 'Gửi',
	'보낸 쪽지': 'Gửi',
	'받는 사람': 'Ng nhận',
	'보낸 쪽지는 90일 후 자동으로 삭제됩니다.': 'Thư đã gửi sẽ được xóa tự động sau 90 ngày.',
	'쪽지 수신 설정': 'Cài đặt nhận tin',
	'전체 수신거부': 'Chặn toàn bộ',
	'전체 이용자의 쪽지 수신을 거부합니다.<br>(운영자 계정 제외)': 'Từ chối tất cả các tin nhắn văn bản.<br>(Không bao gồm ID quản trị)',
	'수신거부 ID 목록': 'Danh sách ID bị chặn',
	'총 %s명': 'Tổng %sngười',
	'아이디': 'ID',
	'추가 날짜': 'Ngày thêm vào',
	'수신거부 목록이 없습니다.': 'Không có địa chỉ bị chặn',
	'수신거부 해제': 'Bỏ chặn ',
	'추가': 'Thêm ',
	'수신거부 추가': 'Thêm vào Địa chỉ bị chặn',
	'쪽지 보내기': 'Gửi thư',
	'보내기': 'Gửi',
	'받는 아이디': 'ID nhận',
	'다중 전송은 엔터키로 구분해 주세요.': 'Phân chia địa chỉ nhận bằng phím Enter',
	'최대 5,000자까지 작성 가능합니다.': 'Bạn có thể viết tối đa 5.000 kí tự.',
	'금칙어 사용으로 인해 기능이 차단되었습니다.차단 내용은 고객센터를 통해 문의해 주세요.': 'Tính năng này đã bị vô hiệu hóa do bạn có sử dụng từ cấm. Vui lòng liên hệ với Trung tâm khách hàng để biết về nội dung bị cấm.',
	'신고가 접수되었습니다.': 'Báo cáo đã được tiếp nhận.',
	'선택한 쪽지를\n신고하시겠습니까?': 'Bạn có muốn báo cáo \n thư đã chọn không?',
	'쪽지를 선택해 주세요.': 'Vui lòng chọn thư.',
	'선택한 쪽지를\n삭제하시겠습니까?': 'Bạn có muốn xóa \n thư đã chọn không?',
	'쪽지를 선택해 주세요.': 'Vui lòng chọn thư.',
	'삭제하시겠습니까?': 'Bạn có muốn xóa không?',
	'신고하시겠습니까?': 'Bạn có muốn báo cáo không?',
	'수신거부 목록에 추가되었습니다.\n해제는 수신거부 설정에서 \n가능합니다.': 'Đã thêm vào danh sách địa chỉ bị chặn.\n Bạn có thể \n bỏ chặn trong Cài đặt Địa chỉ bị chặn.',
	'아이디를 입력해 주세요.': 'Vui lòng nhập ID.',
	'아이디는 영문,숫자만 입력 가능합니다.': 'ID chỉ có thể là chữ, số.',
	'아이디를 선택해 주세요': 'Vui lòng chọn ID.',
	'해제되었습니다.': 'Đã bỏ thành công.',
	'한 번에 최대 30명까지만 전송 가능합니다.': 'Bạn có thể gửi tối đa cho 30 người một lần.',
	'본인에게는 쪽지를 보낼 수 없습니다.': 'Bạn không thể gửi thư cho bản thân.',
	'이미 받는사람 목록에 포함 되어 있습니다.': 'Người này đã có trong danh sách nhận.',
	'%s은(는) 존재하지 않는 닉네임/아이디입니다.': '%s... là Biệt danh/ID không tồn tại.',
	'받는 아이디/닉네임을 입력해 주세요.': 'Vui lòng nhập ID/Biệt danh người nhận.',
	'쪽지 내용을 입력해 주세요.': 'Vui lòng nhập nội dung thư.',
	'받는 사람은 최대 30명까지 입력하실 수 있습니다.': 'Bạn có thể nhập tối đa 30 người nhận.',
	'전송이 완료되었습니다.': 'Gửi thư thành công.',
	'로그인이 되어 있지 않습니다.': 'Bạn chưa đăng nhập.',
	'아이디는 12자 이내로 입력해 주세요.': 'Vui lòng nhập ID trong khoảng 12 kí tự.',
	'신고가 완료되었습니다.(운영자 ID 제외)\n신고 완료된 쪽지를 삭제하시겠습니까?': 'Đã báo cáo thành công. \nBạn có muốn xóa thư đã báo cáo không (ngoại trừ ID của quản trị viên)?',
	'잠시 후 이용해 주세요.': 'Vui lòng sử dụng dịch vụ sau.',
	'수신거부가 완료되었습니다. (운영자 ID 제외)\n지금 수신거부 설정에서 확인하시겠습니까?': 'Chặn địa chỉ thư thành công. \nBạn có muốn kiểm tra cài đặt Địa chỉ bị chặn bây giờ không (ngoại trừ ID của quản trị viên)?',
	'이미지 첨부는 3개까지 가능합니다.': 'Bạn có thể đính kèm tối đa 3 ảnh.',
	'이미지 첨부는 8MB까지 가능합니다.': 'Ảnh đính kèm có dung lượng tối đa 8MB.',
	'이미지가 저장되었습니다.': 'Ảnh đã được lưu.',
	'확인': 'Xác nhận',
	'(받은 날짜로부터 <em>90</em>일 보관)': '(Bảo quản <em>90</em>ngày từ ngày nhận)',
	'이미지 첨부': 'Đính kèm ảnh',
	'첨부 이미지': 'Ảnh đính kèm',
	'이미지 저장 기간': 'Thời hạn lưu ảnh',
	'이미지 저장 기간 만료': 'Hết thời hạn lưu ảnh',
	'(보낸 날짜로부터 <em>90</em>일 보관)': '(Bảo quản <em>90</em>ngày từ ngày gửi)',
	'이미지 파일은 1개씩 첨부 가능합니다.': 'Bạn có thể đính kèm từng tệp ảnh một.',
	'JPG, JPEG, GIF, PNG, BMP 이미지만 첨부 가능합니다.': 'Bạn chỉ có thẻ đính kèm ảnh có đuôi JPG, JPEG, GIF, PNG, BMP.',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.': 'Thư được chuyển tới hòm lưu trữ sẽ tồn tại vĩnh viễn.',
	'보관함으로 이동된 쪽지는 영구 저장됩니다.(최대 1000개)': 'Thư được chuyển tới hòm lưu trữ sẽ ồn tại vĩnh viễn. (Tối đa 1000 thư)',
	'이미지 첨부 중입니다.': 'Đang đính kèm ảnh.',
	'해당 쪽지가 삭제되었습니다.' : 'Đã xóa thư được chọn.',
	'해당 쪽지가 신고되었습니다.' : 'Đã báo cáo thư được chọn.',
	'수신거부가 완료되었습니다.' : 'Chặn địa chỉ thư thành công.',
	'해당 쪽지가 발송 취소되었습니다.' : 'Đã hoãn gửi thư được chọn.',
	'이미 신고된 쪽지입니다.': 'Bạn đã báo cáo thư này.',
	'수신거부 ID 추가' : 'Thêm ID bị chặn',


	'즐겨찾기': 'Yêu thích',
	'즐찾': 'Yêu thích',
	'방송중': 'Đang phát sóng',
	'방송 시작 전': 'Trước khi bắt đầu phát sóng',
	'BJ 랭킹순': 'Sắp xếp theo Thứ hạng BJ',
	'방송 중인 즐겨찾기 BJ가 없습니다.': 'Không có BJ bạn yêu thích đang phát sóng.',
	'방송 시작 전인 즐겨찾기 BJ가 없습니다.': 'Không có BJ bạn yêu thích sắp phát sóng.',
	'즐겨찾기한 BJ가 없거나': 'Bạn không có BJ yêu thích hoặc',
	'생방송 중인 즐겨찾기 BJ가 없습니다.': 'không có BJ bạn yêu thích đang phát sóng trực tiếp.',
	'상세정보': 'Thông tin chi tiết',
	'추천 받지 않기': 'Không nhận gợi ý',
	'즐겨찾기 삭제': 'Xóa Yêu thích',
	'명 시청중': 'người đang xem',
	'BJ랭킹순': 'Sắp xếp theo Thứ hạng BJ',
	'회원가입': 'Đăng ký hội viên',
	'고객센터': 'Trung tâm khách hàng',
	'최근 본 방송': 'Phát sóng xem gần đây',
	'지상파/케이블': 'Truyền hình mặt đất/ Truyền hình cáp',
	'보이는라디오': 'Radio hữu hình',
	'스포츠': 'Thể thao',
	'게임': 'Trò chơi',
	'애니': 'Hoạt hình',
	'콘텐츠발전소': 'Trung tâm phát triển nội dung',
	'별풍선': 'Bóng sao',
	'장애/버그신고': 'Báo cáo lỗi/bug',
	'공지사항': 'Thông báo chung',
	'게임센터': 'Trung tâm trò chơi',
	'더보기': 'Tìm hiểu thêm',
	'신인': 'Thành viên mới',
	'시청자순': 'Sắp xếp theo Người xem',
	'참여자순': 'Sắp xếp theo Người xem',
	'1일': '1 ngày',
	'1주': '1 tuần',
	'1개월': '1 tháng',
	'즐겨찾기할만한방송': 'Phát sóng đáng được yêu thích',
	'아프리카TV앱': 'Ứng dụng AfreecaTV',
	'바로가기': 'Đi',
	'정보': 'Thông tin',
	'댓글': 'Bình luận',
	'승인': 'Đồng ý',
	'인기방송': 'Phát sóng nổi tiếng',
	'내가 보유한 별풍선': 'Bóng sao của tôi',
	'확인': 'Xác nhận',
	'취소': 'Hủy bỏ',
	'스티커': 'Nhãn dán',
	'구매하기': 'Mua hàng',
	'직접입력': 'Tự đánh',
	'초콜릿': 'Sô cô la',
	'로그인상태유지': 'Duy trì trạng thái đăng nhập',
	'개': 'cái',
	'비밀번호': 'Mật khẩu',
	'최근 본 방송 내역 전체 삭제하기': 'Xóa toàn bộ lược sử Phát sóng xem gần đây',
	'전체방송': 'Toàn bộ các phát sóng',
	'모바일게임': 'Trò chơi di động',
	'애니메이션': 'Hoạt hình',
	'먹방/쿡방': 'Mukbang/Kukbang',
	'음악': 'Âm nhạc',
	'시사/현장': 'Sự kiện/Hiện trường',
	'주식/금융': 'Cổ phiếu/Tín dụng',
	'금융': 'Tín dụng',
	'생활/정보': 'Đời sống/Thông tin',
	'공부방': 'Phòng học',
	'보이는쇼핑': 'Shopping hữu hình',
	'케이블': 'Cáp',
	'1:1문의': 'Hỏi đáp 1:1',
	'엔터테인먼트': 'Giải trí',
	'창업방': 'Phòng khởi nghiệp',
	'VOD': 'VOD',
	'개인방송국': 'Đài truyền hình cá nhân',
	'핫이슈동영상': 'Video nổi bật',
	'로그아웃': 'Đăng xuất',
	'방송시작전': 'Trước khi bắt đầu phát sóng',
	'아이디찾기': 'Tìm ID',
	'방송국보러가기': 'Đến Đài truyền hình',
	'연령제한': 'Giới hạn độ tuổi',
	'청소년유해신고': 'Báo cáo nội dung có hại cho thanh thiếu niên',
	'국내야구': 'Bóng chày Hàn Quốc',
	'일본야구': 'Bóng chày Nhật Bản ',
	'프로농구': 'Bóng rổ chuyên nghiệp',
	'프로배구': 'Bóng chuyền chuyên nghiệp',
	'UFC/일반': 'UFC/ Cơ bản',
	'프로골프': 'Golf chuyên nghiệp',
	'특집VOD': 'VOD đặc biệt',
	'스포츠특집': 'Chương trình thể thao đặc biệt',
	'게임특집': 'Chương trình trò chơi đặc biệt',
	'명시청중': 'người đang xem',
	'내가 보유한 골드': 'Đồng vàng của tôi',
	'내가 보유한 초콜릿': 'Sô cô la của tôi',
	'아프리카TV': 'AfreecaTV',
	'아프리카TV,': 'AfreecaTV,',
	'랭킹순': 'Sắp xếp theo Thứ hạng',
	'님이': 'Bạn',
	'비밀번호찾기': 'Tìm mật khẩu',
	'최근본방송 삭제': 'Xóa phát sóng xem gần đây',
	'최근본방송추가': 'Thêm phát sóng xem gần đây',
	'시청한 방송이 없습니다': 'Không có phát sóng đã xem ',
	'지금 바로 아프리카TV의 재미있고': 'và thú vị của Afreeca TV ngay bây giờ!',
	'다양한 방송을 만나보세요!': 'Hãy đến với các phát sóng đa dạng',
	'[생]': '[Trực tiếp]',
	'[재]': '[Phát]',
	'[중계방]': '[Phòng trung gian]',
	'아이디비번찾기': 'Tìm ID và mật khẩu',
	'명시청': 'số người xem',
	'레이디스배틀': 'Lady Battle',
	'등록되지 않은 아이디이거나, 아이디 또는 비밀번호를 잘못 입력하셨습니다.': 'ID này chưa được đăng ký hoặc ID, mật khẩu bị nhập sai.',
	'아이디/비밀번호찾기': 'Tìm ID/Mật khẩu',
	'님이빠져들만한방송': 'Phát sóng bạn sẽ thích',
	'9월에 유니온페이 추가예정입니다. / 추후 이용가능합니다.': 'Tính năng Union Pay sẽ được cập nhật vào tháng Chín. / Có thể sử dụng sau đó.',
	'구매': 'Mua hàng',
	'즐겨찾기추가': 'Thêm vào Yêu thích',
	'베스트BJ방송': 'Phát sóng của BJ tốt nhất',
	'최근 본 방송 내역을 모두삭제하시겠습니까?\n(방송별로 개별 삭제하시려면방송 목록의 더보기 버튼을눌러주세요)': 'Bạn có muốn xóa tất cả các phát sóng được xem gần đây không?\n(Để xóa các chương trình phát sóng riêng lẻ, nhấn nút Tìm hiểu thêm của mục lục)',
	'자신에게는선물하실수없습니다.': 'Bạn không thể tặng quà cho bản thân.',
	'현재시청자수': 'Số lượng người xem hiện tại',
	'모바일': 'Di động',
	'누적 시청자 수': 'Số lượng người xem tích lũy',
	'유머/패러디': 'Hài hước/Parody',
	'베스트BJ': 'BJ tốt nhất',
	'신인BJ': 'BJ tân binh',
	'파트너BJ': 'BJ đối tác',
	'뷰티/패션': 'Làm đẹp/Thời trang',
	'샵프리카': 'Shopfreeca',
	'MY쇼핑': 'My Shopping',
	'마인크래프트': 'Minecraft',
	'히어로즈': 'Heroes',
	'메이플스토리2': 'Maple’s Story 2',
	'피파온라인3': 'FIFA online 3',
	'검은사막': 'Black Desert',
	'블랙스쿼드': 'Black Squad',
	'하스스톤': 'Hearthstone',
	'문명': 'Civilization',
	'버블파이터': 'Bubble Fighter',
	'마비노기': 'Mabinogi',
	'사이퍼즈': 'Cyphers',
	'메이플스토리': 'Maple’s Story',
	'테일즈런너': 'Tales Runner',
	'서든어택': 'Sudden Attack',
	'마영전': 'Mayoungjeon',
	'리니지': 'Lineage',
	'스타': 'Star',
	'스타2': 'Star 2',
	'아키에이지': 'ArcheAge',
	'카트라이더': 'Crazyracing Kartrider',
	'모두의마블': 'Modoo Marble',
	'디아블로3': 'Diablo',
	'월드오브탱크': 'World of Tanks',
	'워크래프트': 'Warcraft ',
	'아이온': 'Aion',
	'던파': 'Dungeon Fighter',
	'스페셜포스': 'Special Force',
	'고전게임': 'Trò chơi cổ truyền',
	'비디오게임': 'Trò chơi video',
	'기타': 'Khác',
	'LOL': 'LOL',
	'BladeNSoul': 'BladeNSoul',
	'WOW': 'WOW',
	'종교': 'Tôn giáo',
	'요리/건강': 'Nấu ăn/ Sức khỏe',
	'자습': 'Học tập',
	'강좌': 'Bài giảng',
	'멘토': 'Hướng dẫn',
	'프로야구': 'Bóng chày chuyên nghiệp',
	'농구': 'Bóng rổ',
	'골프': 'Golf',
	'국내축구': 'Bóng đá trong nước',
	'해외축구': 'Bóng đá quốc tế',
	'스타리그': 'Star League',
	'어학': 'Ngoại ngữ học',
	'지식': 'Kiến thức',
	'취미': 'Sở thích',
	'토크/캠방송': 'Talk/Cam ',
	'나루토': 'Naruto',
	'명탐정코난': 'Thám tử lừng danh Conan',
	'교육': 'Giáo dục',
	'토크/캠방': 'Phòng Talk/Cam',
	'별풍선 &#183; 아이템': 'Bóng sao &#183; Vật phẩm',
	'별풍선ㆍ아이템': 'Bóng saoㆍVật phẩm',
	'보.라': 'Radio hữu hình',
	'엔터': 'Giải trí',
	'파트너BJ방송': 'Phát sóng của BJ đối tác',
	'게임전체': 'Toàn bộ trò chơi',
	'스포츠전체': 'Toàn bộ môn thể thao',
	'보이는라디오전체': 'Toàn bộ radio hữu hình',
	'엔터전체': 'Toàn bộ nội dung giải trí',
	'농구/NBA': 'Bóng rổ / NBA',
	'시청자가밀어주는방송': 'Phát sóng được người xem quảng bá',
	'클랜이밀어주는방송': 'Phát sóng được fanclub quảng bá',
	'PC게임': 'Trò chơi trên PC',
	'멸망전': 'Trận sinh tử',
	'라이프': 'Trực tiếp',
	'라이프전체': 'Toàn bộ phát sóng trực tiếp',
	'더빙/리뷰': 'Lồng tiếng/Đánh giá',
	'격투기/일반': 'Võ thuật / Tổng hợp',
	'샵프리카Beta': 'Shopfreeca Beta',
	'라이브': 'Trực tiếp',
	'배구': 'Bóng chuyền',
	'농구/배구': 'Bóng rổ/Bóng chuyền',
	'별풍선·아이템': 'Bóng sao·Vật phẩm',
	'실시간핫이슈': 'Chủ đề nóng theo thời gian thực',
	'오버워치': 'Overwatch',
	'리니지LFC': 'Lineage LFC',
	'테라': 'TERA',
	'철권': 'Tekken',
	'스파5': 'SF5',
	'스팀게임': 'Steam Game',
	'뽐': 'Ffom',
	'가우스X아프리카TV': 'Gaus x 아프리카TV',
	'유로2016': 'Euro 2016',
	'LoL공식리그': 'LoL Official League',
	'e스포츠': 'e-sport',
	'세계정복2': 'World Conquest 2',
	'클래시로얄': 'Clash Royale',
	'세븐나이츠': 'Seven Knights',
	'레이븐': 'Common raven ',
	'쿠키런': 'Cookie Run',
	'기타게임': 'Trò chơi khác',
	'두산': 'Doosan',
	'한화': 'Hanhwa',
	'롯데': 'Lotte',
	'넥센': 'Nexon',
	'삼성': 'Samsung',
	'중립': 'Trung lập',
	'채널A': 'Channel A',
	'TV조선': 'TV Choson',
	'연합뉴스': 'Yeonhap News',
	'몬길': 'Taming the Monster',
	'서든어택2': 'Sudden Attack 2',
	'BJ리그': 'BJ League',
	'포켓몬GO': 'Pokemon GO',
	'2016BJ대상': 'Giải thưởng BJ 2016',
	'학습': 'Học tập',
	'애니메이션관': 'Quán hoạt hình',
	'콘텐츠제작지원센터': 'Trung tâm hỗ trợ sản xuất nội dung',
	'알려드립니다': 'Xin giới thiệu',
	'개인정보처리방침': 'Phương châm xử lý Thông tin cá nhân',
	'개인정보 수정': 'Sửa chữa thông tin cá nhân',
	'내 정보': 'Thông tin của tôi',
	'로그인 기기관리': 'Quản lý thiết bị đăng nhập',
	'내 방송국': 'Đài truyền hình của tôi',
	'본방': 'Phòng chính',
	'MY뽐': 'MY Ffom',
	'2차비밀번호입력': 'Nhập mật khẩu thứ 2',
	'2차비밀번호': 'Mật khẩu thứ 2',
	'입력내용삭제': 'Xóa nội dung đã nhập',
	'2차비밀번호찾기': 'Tìm mật khẩu thứ 2',
	'MOBILE': 'DI ĐỘNG',
	'나중에설정': 'Cài đặt sau',
	'닉네임': 'Biệt danh',
	'고화질로시청': 'Xem bằng HD',
	'멀티방송': 'Phát sóng đa nhiệm',
	'페이스북': 'Facebook',
	'트위터': 'Twitter',
	'카카오톡': 'Kakao Talk',
	'참여중인중계방': 'Phòng trung gian đang tham gia',
	'멀티방송화면리스트': 'Danh sách màn hình phát sóng đa nhiệm',
	'방송중인 상품은 <span>상품보기</span> 를 눌러<br>구매할 수 있습니다.': 'Bạn có thể <br>nhấn <span>MUA HÀNG</span> để mua sản phẩm đang hiển thị.',
	'앱에서시청하기': 'Xem trên ứng dụng',
	'상품보기': 'Xem sản phẩm',
	'선물하기': 'Tặng quà',
	'전송': 'Gửi',
	'비밀번호 변경': 'Đổi mật khẩu',
	'2차 비밀번호 설정': 'Cài mật khẩu thứ 2',
	'건강': 'Sức khỏe',
	'이슈방송스케줄': 'Lịch phát sóng nổi bật',
	'PC버전으로보기': 'Xem bằng bản PC',
	'LoL챌린저스': 'LoL Challengers',
	'오버워치BJ리그': 'Overwatch BJ League',
	'격투게임': 'Trận đấu kháng',
	'별풍선/아이템 정보': 'Thông tin Bóng sao/ Vật phẩm',

	'VOD가 없습니다.': 'Không có VOD.',
	'UP순': 'Sắp xếp theo Lượt UP',
	'댓글순': 'Sắp xếp theo Lượt bình luận',
	'조회순': 'Xác nhận',
	'1주일': '1 tuần',
	'방송국': 'Đài truyền hình',
	'인기순' : 'Theo phổ biến',

	'사용 중인 퀵뷰가 없습니다.': 'Không có Xem nhanh đang sử dụng.',
	'새로고침': 'Làm mới',
	'사용중': 'Đang sử dụng',
	'무료사용중': 'Sử dụng miễn phí',
	'자동결제': 'Thanh toán tự động',
	'사용기간이': 'Thời gian sử dụng là',
	'일': 'ngày',
	'시간': 'giờ',
	'남았습니다.': 'còn lại.',
	'다음 결제일': 'Ngày thanh toán tiếp theo ',
	'보유 중인 퀵뷰': 'Xem nhanh đang sở hữu',
	'보유중인 퀵뷰가 없습니다.': 'Không có Xem nhanh đang sở hữu.',
	'보유 중인 퀵뷰 선물권': 'Thẻ quà tặng Xem nhanh đang sở hữu',
	'전체 방송': 'Tất cả các phát sóng',
	'아이템효과 닫기': 'Tắt Hiệu ứng vật phẩm',
	'채팅토글': 'Chatting toggle',
	'앱에서 참여하기': 'Tham gia vào ứng dụng',
	'아프리카TV 앱에서 재생하기': 'Xem trên ứng dụng AfreecaTV',
	'이모티콘': 'Biểu tượng cảm xúc',

	'광고 Skip, Full방 입장, 타임머신 기능 등<br>다양한 혜택의 <strong>퀵뷰 아이템을</strong> 사용해보세요.': 'Hãy sử dụng <strong>Item Xem nhanh </strong> để nhận các ưu đãi như skip quảng cáo, vào phòng full và chức năng Cỗ máy thời gian',
	'아프리카TV 상품권 등록 하세요!': 'Hãy đăng kí thẻ dịch vụ AfreecaTV!',

	'즐겨찾기가 추가되었습니다.': 'Đã thêm Yêu thích.',
	'바람의나라': 'World of Wind',
	'닉네임 변경': 'Đổi biệt danh',
	'한글 6자(영문 12자) 내로 닉네임을 변경할 수 있습니다.': 'Bạn có thể sử dụng 6 kí tự tiếng Hàn (12 kí tự tiếng Anh) để làm biệt danh.',
	'닉네임 변경 시, 24시간 후에 재변경이 가능합니다.': 'Bạn có thể tiếp tục thay đổi biệt danh sau 24 giờ.',
	'닉네임 적용하기': 'Áp dụng biệt danh này',
	'중복확인': 'Kiểm tra',
	'내정보': 'Thông tin của tôi',
	'퀵뷰 정보': 'Thông tin Xem nhanh',
	'아이템': 'Vật phẩm',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다. 로그인 페이지로 이동하시겠습니까?': 'Bạn cần phải đăng nhập để có thể thêm Yêu thích. Bạn có muốn đi tới trang đăng nhập không?',
	'해당 방송을 즐겨찾기에서\n삭제 하시겠습니까?': 'Bạn có muốn xóa\nphát sóng này khỏi mục Yêu thích không?',
	'해당 방송을 즐겨찾기에서 삭제하시겠습니까?': 'Bạn có muốn xóa phát sóng này khỏi mục Yêu thích không?',

	'사용하기': 'Sử dụng',
	'블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되어 입장이 불가합니다.': 'Trường hợp rời phòng trong trạng thái blind được coi là bị cưỡng chế rời phòng và không thể vào lại.',
	'고객센터 문의하기': 'Liên hệ Trung tâm khách hàng',
	'비밀번호 설정 방송': 'Phát sóng có cài mật khẩu',
	'비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 시청할 수 있습니다.': 'Phát sóng này có cài mật khẩu.<br>Bạn chỉ có thể xem nó trên ứng dụng AfreecaTV.',
	'연령제한 방송': 'Phát sóng có giới hạn độ tuổi',
	'19세 미만 시청 제한된 방송입니다.<br>아프리카TV 앱에서만 시청할 수 있습니다.': 'Phát sóng này giới hạn với người dưới 19 tuổi.<br>Bạn chỉ có thể xem nó trên ứng dụng AfreecaTV.',
	'연령제한 / 비밀번호 설정 방송': 'Phát sóng có cài mật khẩu / giới hạn độ tuổi',
	'19세 미만 시청 제한과 비밀번호가 설정된 방송입니다.<br>아프리카TV 앱에서만 시청할 수 있습니다.': 'Phát sóng này có cài mật khẩu và giới hạn độ tuổi.<br>Bạn chỉ có thể xem nó trên ứng dụng AfreecaTV.',
	'현재 BJ가 방송 중이 아닙니다.<br>아프리카TV 앱으로 다양한 생방송을 즐길 수  있습니다.': 'BJ này hiện đang không phát sóng.<br>Hãy đến với các phát sóng trực tiếp đa dạng trên ứng dụng AfreecaTV.',
	'안드로이드 2.3.7 이하 버전은<br>아프리카TV 앱을 설치해야만 방송 시청이 가능합니다.': 'Hệ điều hành Android dưới 2.3.7<br>chỉ hỗ trợ xem phát sóng nếu cài đặt ứng dụng AfreecaTV.',
	'BJ가 방송을 <strong>일시정지</strong> 하였습니다.': 'BJ đã <strong>tạm thời ngừng</strong> phát sóng.',
	'다시 시작될 때까지 잠시만 기다려주세요.': 'Vui lòng đợi một lát để phát sóng bắt đầu lại.',

	'강제퇴장 및 이용정지 관련한 문의사항이 <br>있으실 경우 고객센터를 통해 접수를 부탁드리겠습니다.': 'Các thắc mắc liên quan đến trường hợp bị cưỡng chế rời phòng hoặc bị đình chỉ dịch vụ <br>sẽ được tiếp nhận tại Trung tâm khách hàng.',
	'보유 중인 퀵뷰 아이템과 유효기간을 확인 후, [사용하기]를 누르시면 해당 기간 동안 퀵뷰 아이템을 이용하실 수 있습니다.': 'Sau khi xác nhận số Xem nhanh đang sở hữu và thời hạn sử dụng, hãy nhấn nút [Sử dụng] để bắt đầu sử dụng Xem nhanh trong thời gian cho phép.',

	'즐겨찾기가 삭제되었습니다.': 'Đã xóa khỏi Yêu thích.',
	'알람': 'Thông báo',
	'유료': 'Có phí',
	'알람 닫기': 'Đóng thông báo',
	'알림 예약': 'Đặt thông báo',
	'알림 취소': 'Hủy thông báo',
	'현재 진행 중인 방송이 없습니다.': 'Không có phát sóng đang tiến hành lúc này.',
	'곧,  BJ들의 재미있고 다양한 방송이': 'Một lát thôi, các phát sóng đa dạng và thú vị của các BJ',
	'시작됩니다.': 'sẽ bắt đầu.',
	'<strong>현재 진행 중인 방송이 없습니다.</strong><br> 곧,  BJ들의 재미있고 다양한 방송이<br> 시작됩니다.': '<strong>Không có phát sóng đang tiến hành lúc này.</strong><br> Một lát thôi, các phát sóng đa dạng và thú vị của các BJ<br> sẽ bắt đầu.',
	'방송이 시작되면': 'Nếu phát sóng bắt đầu',
	'알려 드릴까요?': 'chúng tôi báo cho bạn nhé?',
	'<em></em>이미 알림 예약을<br>하셨습니다': '<em></em>Bạn đã đặt<br>một thông báo.',
	'운영자에 의해<br ng-show="newLine">': 'Bởi quản trị viên<br ng-show="newLine">',
	'되었습니다': 'đã',
	'운영자 강제퇴장 안내': 'Thông báo đuổi khỏi phòng bởi quản trị viên',
	'강제퇴장': 'Bị cưỡng chế rời phòng',
	'회차': 'tập',
	'(채팅금지 24시간)': '(Cấm trò chuyện trong 24 giờ)',
	'강제퇴장 및 3일 이용정지': 'Cưỡng chế rời phòng và đình chỉ sử dụng dịch vụ trong 3 ngày',
	'강제퇴장 및 7일 이용정지': 'Cưỡng chế rời phòng và đình chỉ sử dụng dịch vụ trong 7 ngày',
	'강제퇴장 되어 방송을 보실 수 없습니다.': 'Bạn đã bị cưỡng chế rời phòng nên không thể xem phát sóng.',
	'앱을 설치하시면 구매가 가능합니다.': 'Nếu cài ứng dụng, bạn sẽ có thể mua hàng.',
	'[안내]': '[Hướng dẫn]',
	'방송에 입장하였습니다.': 'Bạn đã vào phòng phát sóng.',
	'채널 퇴장하셨습니다.': 'Bạn có muốn rời khỏi kênh này không?',
	'로그아웃 하시겠습니까?': 'Bạn có muốn đăng xuất không?',
	'알림 예약을 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'Bạn cần phải đăng nhập để có thể đặt thông báo.\nBạn có muốn đăng nhập không?',
	'알림 예약이 완료되었습니다.': 'Đã đặt thông báo.',
	'알림 예약이 취소되었습니다.': 'Đã hủy thông báo.',
	'19세 미만 시청 제한된 방송입니다.아프리카TV앱에서만 시청할 수 있습니다.\n\n아프리카TV앱에서 시청하시겠습니까?': 'Phát sóng này giới hạn với người dưới 19 tuổi. Bạn chỉ có thể xem nó trên ứng dụng AfreecaTV.\n\nBạn có muốn xem trên ứng dụng AfreecaTV không?',
	'유효기간': 'Thời hạn sử dụng',
	'등록되었습니다.': 'Đã đăng ký.',
	'퀵뷰를 이용 중에는 등록이 불가능합니다.': 'Bạn không thể đăng ký trong khi đang sử dụng Xem nhanh.',
	'오류가 발생하여 이용하실수 없습니다.': 'Bạn không thể sử dụng do có lỗi phát sinh.',

	'검색결과': 'Kết quả tìm kiếm',
	'검색결과가 없습니다.': 'Không có kết quả tìm kiếm.',
	'닉네임 변경이 완료되었습니다. 24시간 후 재변경이 가능합니다.': 'Đã đổi biệt danh thành công. Bạn có thể tiếp tục đổi biệt dánh sau 24 giờ.',
	'사용할 수 없는 닉네임입니다.': 'Biệt danh này không thể sử dụng được.',
	'닉네임 중복 확인을 해주세요.': 'Vui lòng kiểm tra trùng lặp biệt danh.',
	'채팅입력이 지원되지 않는 브라우져입니다. 아프리카TV앱을 이용해주세요': 'Trình duyệt này không hỗ trợ tính năng trò chuyện. Vui lòng sử dụng ứng dụng AfreecaTV',

	'비밀번호가 설정된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'Phát sóng này có đặt mật khẩu.\nBạn chỉ có thể xem nó trên ứng dụng AfreecaTV.\n\nBạn có muốn xem trên ứng dụng AfreecaTV không?',
	'19세 미만 시청 제한된 방송입니다.\n아프리카TV 앱에서만 시청할 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'Phát sóng này giới hạn với người dưới 19 tuổi.\nBạn chỉ có thể xem nó trên ứng dụng AfreecaTV.\n\nBạn có muốn xem trên ứng dụng AfreecaTV không?',

	'본 방송은 저작권 문제로 인하여 해외 지역에서 시청할 수 없습니다.': 'Phát sóng này không thể phát ở nước ngoài ví lý do bản quyền.',
	'강제퇴장 되어 방송을 볼 수 없습니다.': 'Bạn không thể xem phát sóng do bị cưỡng chế rời phòng.',
	'로그인 되었습니다.': 'Bạn đã đăng nhập.',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다. 다시 시도하시겠습니까?': 'Không thể tải dữ liệu do kết nối mạng kém. Bạn có muốn thử lại không?',
	'네트워크 연결이 좋지 않아 일시적으로 데이터를 불러오지 못했습니다.\n\n다시 시도하시겠습니까?': 'Không thể tải dữ liệu do kết nối mạng kém.\n\nBạn có muốn thử lại không?',
	'채팅 메세지를 입력하세요.': 'Vui lòng nhập tin nhắn trò chuyện.',
	'1개 이상 선물이 가능합니다.': 'Bạn có thể tặng từ 1 món quà trở lên.',
	'보유하신 별풍선이 부족합니다.': 'Bạn không có đủ bóng sao.',
	'보유하신 골드가 부족합니다.': 'Bạn không có đủ đồng vàng.',
	'보유하신 초콜릿이 부족합니다.': 'Bạn không có đủ sô cô la.',
	'선물하기 서버 문제로 이용하실 수 없습니다.': 'Không thể tặng quà do lỗi máy chủ.',
	'로그인 하셔야 선물 하실 수 있습니다.': 'Bạn phải đăng nhập để có thể tặng quà.',

	'즐겨찾기 바로가기': 'Đến Yêu thích',
	'멀티방송 화면리스트': 'Danh sách màn hình phát sóng đa nhiệm',
	'현재 시청자수': 'Số người xem hiện tại',
	'중계방': 'Phòng trung gian',
	'PC버전으로 보기': 'Xem bằng phiên bản PC',
	'앱에서 보기': 'Xem bằng ứng dụng',
	'명': 'người',

	'본방입장': 'Vào phòng chính',
	'방송보기': 'Xem phát sóng',
	'잠시 쉬어갑니다.': 'Tạm dừng trong giây lát.',
	'점검시간': 'Thời gian bảo trì',
	'점검영향': 'Hiệu quả bảo trì',
	'서비스 안정화를 위한 시스템 점검 시간입니다.': 'Bây giờ là thời gian bảo trì vì tính ổn định của dịch vụ.',
	'이용에 불편을 드려 죄송합니다.': 'Chúng tôi xin lỗi vì sự bất tiện này.',
	'더욱 안정적이고 튼실한 서비스가 되도록 최선을 다하겠습니다.': 'Chúng tôi sẽ hết mình để mang tới dịch vụ an toàn và ổn định hơn.',
	'채팅안보기 해제': 'Tắt tính năng Ẩn trò chuyện',
	'채팅안보기': 'Ẩn trò chuyện',

	'즐겨찾기에 추가되었습니다.': 'Đã thêm vào Yêu thích.',
	'[아프리카TV 안내]': '[Hướng dẫn về AfreecaTV]',
	'운영자 안내': 'Hướng dẫn của quản trị viên',
	'운영자': 'Quản trị viên',
	'BJ': 'BJ',
	'매니저': 'Quản lý',
	'클린아티': 'Clean Artis',
	'%s에 의해 강제퇴장 되었습니다.': '%sBạn đã bị cưỡng chế rời phòng bởi...',
	'%s님은 %s에 의해 채팅금지 %s회가 되셨습니다.': '%sBạn đã bị cấm trò chuyện bởi %strong %slần.',

	'로그인 서버오류가 발생하였습니다.': 'Phát sinh lỗi máy chủ khi đăng nhập.',
	'지원하지 않는 OS버전입니다.': 'Phiên bản OS này không được hỗ trợ.',
	'아프리카TV 앱으로 시청해주세요.': 'Vui lòng xem bằng ứng dụng AfreecaTV.',
	'버퍼링 중': 'Đang tải',
	'애청자': 'Người xem thường xuyên',
	'팬클럽': 'Fanclub',
	'통합검색': 'Tìm All',
	'게시글': 'Bài viết',
	'생방송': 'Phát sóng trực tiếp',
	'정확도순': 'Sắp xếp theo Độ chính xác',
	'방송시간순': 'Sắp xếp theo Thời gian phát sóng',
	'누적시청자순': 'Sắp xếp theo Số người xem tích lũy',
	'최신방송순': 'Thứ tự mới nhất',
	'적용 되었습니다.': 'Đã áp dụng.',
	'채팅창을 얼렸습니다.': 'Đã mở cửa sổ trò chuyện.',
	'채팅 참여 등급이 변경되었습니다.': 'Đã thay đổi thứ bậc tham gia trò chuyện.',
	' BJ와 매니저만 채팅에 참여할 수 있습니다.': ' Chỉ có BJ và quản lý có thể tham gia trò chuyện.',
	' BJ와 매니저, 팬클럽만 채팅에 참여할 수 있습니다.': ' Chỉ có BJ, quản lý và fanclub có thể tham gia trò chuyện.',
	' BJ와 매니저, 서포터만 채팅에 참여할 수 있습니다.': ' Chỉ có BJ, quản lý và người ủng hộ có thể tham gia trò chuyện.',
	' BJ와 매니저, 팬클럽, 서포터만 채팅에 참여할 수 있습니다.': ' Chỉ có BJ, quản lý, fanclub và người ủng hộ có thể tham gia trò chuyện.',
	'채팅창을 녹였습니다. 채팅에 참여할 수 있습니다.': 'Đã khóa cửa sổ trò chuyện. Bạn có thể tham gia vào trò chuyện.',

	"입력하신 \'%s\'는(은) 검색이 제한된 금칙어가 포함되어 있어 이용하실 수 없습니다." : "\'%s\' đã nhập chứa các từ bị cấm có giới hạn tìm kiếm và không được sử dụng.",
	'검색시스템에 문제가 발생했습니다.': 'Hệ thống tìm kiếm phát sinh lỗi.',
	'검색어를 입력하세요.': 'Vui lòng nhập từ khóa tìm kiếm.',
	'|중계방송|': '|Chương trình tiếp sóng|',

	'BJ가 방송을 종료하였습니다.': 'BJ đã kết thúc phát sóng.',
	"\'%s\'님의 방송을 더 이상 추천을 받지 않겠습니까?": "\'%s\' Would you like to refuse UP for your broadcast?",
	'본방입장을 위해서는 퀵뷰아이템이 필요합니다. 지금 바로 퀵뷰 구매페이지로 이동하시겠습니까?': 'Bạn cần sử dụng Xem nhanh để có thể vào phòng chính. Bạn có muốn đến trang mua hàng để mua Xem nhanh bây giờ không? ',
	'이 방송은 모바일 라이브 캠 방송으로 BJ가 방송을 일시정지할 경우, 방송이 멈추거나 검은 화면이 노출될 수 있습니다.(일시정지가 해제되면 방송이 다시 재생됩니다.': 'Chương trình này được phát trực tiếp qua thiết bị di động. Nếu BJ tạm ngừng chương trình, phát sóng sẽ bị dừng lại hoặc màn hình chuyển sang màu đen. (Phát sóng sẽ được phát lại khi chương trình không bị ngừng nữa.',
	'UP은 아프리카TV앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'Bạn có thể UP trên ứng dụng AfreecaTV.\n\nBạn có muốn xem bằng ứng dụng AfreecaTV không?',
	'고화질 시청은 아프리카TV 앱에서 하실 수 있습니다.\n\n아프리카TV 앱에서 시청하시겠습니까?': 'Bạn có thể xem bằng định dạng HD trên ứng dụng AfreecaTV.\n\nBạn có muốn xem bằng ứng dụng AfreecaTV không?',
	' 이상~': ' Hết ~ ',

	'%s님은 %s에 의해 채팅금지 되었습니다. 30초 동안 채팅이 금지 됩니다.': '%sBạn đã bị cấm trò chuyện bởi %s. Cấm trò chuyện trong 30 giây.',
	'%s님이 %s에 의해 채팅금지 %s회가 되셨습니다.': '%sBạn đã bị cấm trò chuyện bởi %strong %slần.',
	'%s님은 %s에 의해 채팅금지 되었습니다. 60초 동안 채팅이 금지 됩니다.': '%sBạn đã bị cấm trò chuyện bởi %s. Cấm trò chuyện trong 60 giây.',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리되었습니다.': '%sBạn đã vượt quá số lần bị cấm trò chuyện bởi %snên đã bị blind trong 2 phút.',
	'%s님은 %s에 의해 채팅금지 횟수 초과로 인해 2분간 블라인드 처리됩니다. 블라인드 상태에서는 화면과 채팅이 보이지 않으며 블라인드 상태로 방송에서 나갈 경우 자동 강제퇴장 처리되며 방송 재입장이 불가능 합니다.': '%sBạn đã vượt quá số lần bị cấm trò chuyện bởi %snên sẽ bị blind trong 2 phút. Trong trạng thái blind, màn hình và trò chuyện sẽ không được hiển thị. Trường hợp rời phòng trong trạng thái blind sẽ được coi là bị cưỡng chế rời phòng và không thể vào lại.',
	'%s(%s) 님이 블라인드 상태에서 탈출을 시도하여 강제퇴장 처리되었습니다.': '%s(%s) đã rời phòng trong trạng thái blind và đã bị áp dụng cưỡng chế rời phòng.',
	'%s(%s)님이 강제퇴장 처리되었습니다.': '%s(%s) đã bị áp dụng cưỡng chế rời phòng.',
	'%s(%s)님이 강제퇴장 취소 처리되었습니다.': '%s(%s) đã được hủy áp dụng cưỡng chế rời phòng.',
    '%s님이 별풍선 %s개를 선물했습니다!': '%sBạn đã tặng %squả bóng sao!',
    '중계방에서 %s님이 별풍선 %s개를 선물했습니다!': 'Bạn %strong phòng trung gian đã tặng %squả bóng sao!',
    'VOD에서 %s님이 별풍선 %s개를 선물했습니다!': 'Bạn %strong VOD đã tặng %squả bóng sao!',
    '%s님이 영상풍선 %s개를 선물했습니다!': '%sBạn đã tặng %squả bóng video!',
    '중계방에서 %s님이 영상풍선 %s개를 선물했습니다!': 'Bạn %strong phòng trung gian đã tặng %squả bóng video!',
	'%s님이 %s번째로 팬클럽이 되셨습니다.': '%sBạn đã trở thành người gia nhập fanclub thứ %s.',
	'%s님이 스티커 %s개를 선물했습니다!': '%sBạn đã tặng %snhãn dán!',
	'%s님이 %s번째로 서포터가 되셨습니다.': '%sBạn đã trở thành người hỗ trợ thứ %s.',
	'중계방에서 %s님이 스티커 %s개를 선물했습니다!': 'Bạn %sở phòng trung gian đã tặng %snhãn dán!',
	'%s님이 초콜릿 %s개를 선물했습니다!': '%sBạn đã tặng %ssô cô la!',
	'중계방에서 %s님이 초콜릿 %s개를 선물했습니다!': 'Bạn %sở phòng trung gian đã tặng %ssô cô la!',
	'%s 님이 %s님에게 퀵뷰 %s일권을 선물했습니다!': '%s Bạn đã tặng cho bạn %sthẻ Xem nhanh %sngày!',
	'모바일 웹에서는 참여하실 수 없습니다.\nPC나 모바일앱(IOS,AOS)에서 참여해주세요.': 'Bạn không thể xem bằng trình duyệt web trên điện thoại.\nVui lòng sử dụng PC hoặc ứng dụng trên điện thoại (IOS,AOS).',

	'지금 시청하시던 방송은 청소년 유해매체물로서 정보통신망 이용촉진 및 정보보호 등에 관한 법률 및 청소년 보호법의 규정에 의해 \n\
                      \n\만19세 미만의 청소년이 이용할수 없도록 방송정보가 변경되었습니다. \n\
                          \n\ 계속시청하려면 앱을 이용하여 본인 인증이 필요합니다.': "Current broadcast may be harmful for teenagers by the by the provisions of the Act on Promotion of Information Network Usage and Information Protection, etc. and the Juvenile Protection Act \n\
                                                        \n\The stream rank has been changed so that tennagers under 19 can't use the service. \n\
                                                        \n\ To continue watching, you need to verify your identity using the app.",

	'아프리카TV에서 %s을 보고 있어요. 함께 보실래요?': 'Đang xem %strên AfreecaTV. Bạn có muốn xem cùng không?',
	'채팅입력이 지원되지 않는 브라우져입니다.': 'Trình duyệt này không hỗ trợ tính năng trò chuyện.',

	'<span>아프리카TV <strong>상품권 등록</strong> 하세요!</span>': '<span>Hãy <strong>đăng ký thẻ mua sản phẩm</strong> của AfreecaTV!</span>',
	'지원하지 않는 OS버전입니다.<br><span>아프리카TV 앱</span>으로 시청해주세요.': 'Phiên bản OS này không được hỗ trợ. Vui lòng xem bằng<br><span>ứng dụng AfreecaTV</span>.',
	'%s님의 채팅이<br>다시 보이기 시작합니다.': '%sTrò chuyện của bạn<br>bắt đầu được hiển thị lại.',
	'%s님의 채팅이<br>지금부터 보이지 않습니다.': '%sTrò chuyện của bạn<br>sẽ ngừng hiển thị từ bây giờ.',

	'랭킹 홈': 'Trang chủ Thứ hạng',
	'최고 유저 평균': 'Trung bình số người xem cao nhất',
	'애청자 증가수': 'Số khán giả trung thành tăng',
	'UP 수': 'Số lượt UP',
	'펫방': 'Phòng thú cưng',
	'더빙/라디오': 'Thuyết minh/Radio ',
	'VOD BJ': 'VOD BJ',
	'VOD FAN': 'VOD FAN',
	'누적 애청자수': 'Số người xem thường xuyên tích lũy',
	'누적 팬클럽수': 'Số thành viên fanclub tích lũy',
	'랭킹 소통방 바로가기': 'Đến Phòng truyền thông thứ hạng',
	'BJ랭킹 산정 방식 안내': 'Hướng dẫn cách tính toán thứ hạng BJ',
	'BJ랭킹은 지난 월요일~일요일까지의 방송활동을 아래의 비율로 반영하여 선정하고 있습니다.': 'Thứ hạng của BJ được lựa chọn để phản ánh tỉ lệ hoạt động phát sóng từ thứ hai đến chủ nhật tuần trước.',
	'(단, 애청자의 경우 누적 애청자 수로 반영됩니다)': '(Tuy nhiên, đối với trường hợp của người xem thường xuyên thì nó sẽ được phản ánh bởi số người xem tích lũy)',
	'VOD BJ 랭킹 산정 방식 안내': 'Hướng dẫn cách tính toán thứ hạng VOD BJ ',
	'VOD BJ 랭킹은 직접 방송한 다시보기 및 본인 방송국에 업로드한 VOD에 따른 순위입니다.': 'Thứ hạng VOD BJ là thứ hạng dựa theo VOD được tải lên Đài truyền hình và số lượt Xem lại của phát sóng của người đó.',
	'지난 월요일~일요일까지 받은 총 VOD 조회 증가수': 'Tổng số lượt xem tăng lên của VOD từ thứ hai đến chủ nhật tuần trước.',
	'VOD FAN 랭킹 산정 방식 안내': 'Hướng dẫn cách tính thứ hạng VOD FAN',
	'VOD FAN 랭킹은 좋아하는 BJ의 방송을 편집하여 업로드 한 팬의 VOD 순위 입니다.': 'Thứ hạng VOD FAN là thứ hạng VOD do người hâm mộ chỉnh sửa từ phát sóng của BJ mình yêu thích rồi tải lên.',

	'변경': 'Thay đổi',
	'아프리카TV 앱 실행하기': 'Chạy ứng dụng AfreecaTV',
	'최고 유저': 'Người xem tuyệt nhất',
	'BJ랭킹': 'Thứ hạng BJ',
	'<span>아프리카TV</span> 앱을 이용하시면<br>채팅과 함께 <span>최고화질(원본)로 참여</span>하실 수 있습니다.': 'Nếu bạn sử dụng<span>ứng dụng AfreecaTV</span><br>bạn có thể<span>tham gia trò chuyện với chất lượng cao</span>.',
	'고화질로 시청': 'Xem bằng chất lượng HD',

	'년 전': 'năm trước',
	'개월 전': 'tháng trước',
	'주 전': 'tuần trước',
	'일 전': 'ngày trước',
	'시간 전': 'giờ trước ',
	'분 전': 'phút trước',
	'초 전': 'giây trước',
	'보낸 시각': 'Thời điểm gửi',
	'공유하기': 'Chia sẻ',
	'검색': 'Tìm',
	'<strong>아프리카TV앱</strong><span>바로가기</span><em></em>': '<strong>Đến</strong><span>ứng dụng AfreecaTV</span><em></em>',
	'나중에 보기를 하시려면 로그인이 필요합니다.\n로그인 하시겠습니까?': 'Bạn cần phải đăng nhập để chọn Xem sau.\nBạn có muốn đăng nhập không?',
	'누적 애청자 수': 'Số người xem thường xuyên tích lũy',
	'누적 팬클럽 수': 'Số người hâm mộ tích lũy',
	'BJ 랭킹': 'Thứ hạng BJ',

	'즐겨찾기 전체': 'Toàn bộ mục Yêu thích',
	'최신등록순': 'Sắp xếp theo Đăng ký mới nhất',
	'시청인원순': 'Sắp xếp theo Số người xem',
	'닉네임순': 'Thứ tự Nick',
	'아이디순': 'Sắp xếp theo ID',
	'최신방송순': 'Thứ tự mới nhất',
	'LIVE 시청': 'Xem LIVE',
	'목록 상단 고정 완료': 'Ghim vào đầu mục lục thành công',
	'라이브&amp;VOD': 'LIVE&amp;VOD',
	'방송국 개설일': 'Ngày lập Đài truyền hình',
	'최근방송': 'Phát sóng gần đây',
	'LIVE 시청하기': 'Xem LIVE',
	'방송국가기': 'Đến Đài truyền hình',
	'목록 상단 고정': 'Ghim vào đầu mục lục',
	'즐겨찾기한 BJ가 없습니다.': 'Không có BJ yêu thích.',
	'목록 상단 고정 해제': 'Gỡ khỏi đầu mục lục',
	'현재 방송 중인<br> 즐겨찾기 BJ가 없습니다.': 'Không có BJ bạn yêu thích<br> đang phát sóng.',
	'즐겨찾기 한 BJ의<br>VOD가 없습니다.': 'Không có VOD <br>của BJ bạn yêu thích.',
	'좋아하는 BJ를 즐겨찾기 하시면 해당 BJ의 라이브나 VOD을 보다 쉽게 시청하실 수 있습니다.': 'Nếu thêm BJ bạn quan tâm vào mục Yêu thích, bạn sẽ có thể xem dễ dàng các VOD và LIVE của BJ đó.',
	'상단고정 완료되었습니다': 'Đã ghim vào đầu mục',
	'상단고정 해제 되었습니다': 'Đã gỡ khỏi đầu mục',
	'지원하지 않는 메뉴입니다.': 'Menu này không hỗ trợ.',
	'수신거부 해제되었습니다.' : 'Đã bỏ chặn địa chỉ.',
	'필터': 'Bộ lọc',
	'정렬기준': 'Tiêu chuẩn sắp xếp',
	'구분': 'Phân loại',
	'날짜': 'Ngày tháng',
	'취소': 'Hủy bỏ',
	'적용': 'Áp dụng',
	'생방송': 'Phát sóng trực tiếp',
	'업로드VOD': 'Tải VOD',
	'유저클립': 'Clip người dùng',
	'다시보기': 'Xem lại',
	'하이라이트': 'Tiêu điểm',
	'구독전용': 'Chỉ đăng ký',
	'1년': '1 năm',
	'고화질순': 'Sắp xếp theo chất lượng HD',
	'고해상도순': 'Sắp xếp theo Độ phân giải cao',
	'애청자순': 'Sắp xếp theo Số người xem thường xuyên',
	'팬클럽순': 'Sắp xếp theo Số người hâm mộ',
	'전체알림': 'Thông báo All',
	'모든 즐겨찾기 BJ에게 알림을 받습니다.': 'Nhận thông báo từ tất cả các BJ yêu thích.',
	'모든 즐겨찾기 BJ에게 알림을 받지 않습니다.': 'Không nhận thông báo từ tất cả các BJ yêu thích.',
	'%s님에게 알림을 받습니다.': 'Nhận được thông báo từ %s.',
	'%s님에게 알림을 받지 않습니다.': 'Không nhận được thông báo từ %s.',

	'방송제목 변경이력': 'Lịch sử đổi tên phát sóng',
	'조회순': 'Theo lượt xem',
	'관련된 VOD': 'VOD liên quan',
	'검색어와 연관된 추천 영상입니다.': 'Clip đề cử liên quan với từ tìm kiếm',
	'게시글': 'Bài viết',
	'최근 1개월간 게시글 검색 결과가 없습니다.<br> 검색 기간을 1개월에서 1년으로 변경하시면<br> 더 많은 게시글이 노출됩니다.': 'No search results returned.<br> Change the criteria from one month up to a year<br> to see more results.',
	'조회순': 'Theo lượt xem',

	'최근 검색어': 'Mới nhất',
	'실시간 인기 검색어': 'Thịnh hành',
	'개인화 추천검색어': 'Cá nhân hóa',
	'자동저장 <span>끄기</span>': 'Tự động lưu <span>tắt</span>',
	'자동저장 <span>켜기</span>': 'Tự động lưu <span>trên</span>',
	'최근 검색어가 없습니다.': 'Không có cụm từ tìm kiếm nào gần đây.',
	'최근 검색어 자동저장이 꺼져 있습니다.': 'Tự động lưu các cụm từ tìm kiếm gần đây đã tắt.',
	'자동저장 켜기': 'Tự động lưu trên',
	'로그인하시면 <span>개인화 추천검색어</span> 서비스를<br>이용하실 수 있습니다.': 'Nếu bạn đăng nhập<br>bạn có thể sử dụng <strong>dịch vụ cụm từ tìm kiếm cá nhân</strong>',
	'최근검색어를\n모두 삭제하시겠습니까?': 'Bạn có muốn xóa tất cả\ncác cụm từ tìm kiếm gần đây không?',
	'최근검색어 자동저장을 사용합니다.': 'Sử dụng tính năng tự động lưu các cụm từ tìm kiếm gần đây.',
	'최근검색어 자동저장 사용을 중지합니다.': 'Không sử dụng tính năng lưu tự động các cụm từ tìm kiếm gần đây.',
	'개인화 추천 검색어가 없습니다.': 'Không có cụm từ tìm kiếm cá nhân.',
	'검색한 검색결과입니다.': 'Tìm kiếm kết quả cho',
	'검색결과 보기': 'Xem kết quả tìm kiếm cho',

	'나중에 보기' : 'Xem sau',
	'즐겨찾기한 BJ의 VOD' : 'VOD của BJ bạn yêu thích',
	'즐겨찾기한 BJ의 VOD가 없습니다.' : 'Không có VOD của BJ yêu thích.',
	'즐겨찾기한 BJ의 방송이 없습니다.' : 'Không có phát sóng của BJ bạn yêu thích.',
	'최근 본 VOD 목록을 모두 삭제하시겠습니까?' : 'Bạn có muốn xóa mục lục VOD xem gần đây không? ',
	'재생한 VOD를 모두 삭제하시겠습니까?' : 'Bạn có muốn xóa toàn bộ VOD đã xem không?',
	'UP 한 VOD가 없습니다.' : 'Không có VOD đã UP.',
	'즐찾 BJ': 'BJ yêu thích',
	'구독 BJ': 'BJ đăng ký',
	'구독중인 BJ' : 'BJ đang đăng ký theo dõi',
	'VOD 홈' : 'Trang chủ VOD',
	'인기 VOD' : 'VOD nổi tiếng',
    '인기' : 'Nổi tiếng',
	'핫이슈 VOD' : 'VOD vấn đề HOT',
    'TV클립' : 'TV Clip',
	'인기 하이라이트' : 'Tiêu điểm nổi tiếng',
	'드라마 인기클립' : 'Clip nổi tiếng mục phim truyền hình',
	'예능 인기클립' : 'Clip nổi tiếng mục chương trình giải trí',
	'스포츠 인기클립' : 'Clip nổi tiếng mục thể thao',
	'아프리카TV X TV클립' : 'AfreecaTV X TV Clip',
	'예능' : 'Chương trình giải trí',
	'교양' : 'Giáo dục đại cương',
	'시사' : 'Thời sự',
	'드라마' : 'Phim truyền hình',
	'스포츠' : 'Thể thao',
	'패션/뷰티' : 'Thời trang / Làm đẹp',
	'어린이' : 'Thiếu nhi',
	'스페셜' : 'Đặc biệt',
	'디지털' : 'Kỹ thuật số',
	'뮤직' : 'Âm nhạc',
	'라디오' : 'Radio',
	'키즈' : 'Trẻ em',
	'연예' : 'Người nổi tiếng',
	'시사보도' : 'Bản tin thời sự',
	'라이프' : 'Cuộc sống',
	'홈페이지' : 'Trang chủ',
	'주목할만한 클립' : 'Clip gây chú ý',
	'인기 클립' : 'Clip nổi tiếng',
	'클립 전체' : 'Toàn bộ các clip',
	'프로그램 전체' : 'Toàn bộ các chương trình',
	'TV클립 목록이 없습니다.' : 'Không có danh sách về TV Clip',
    '인기 프로그램' : 'Chương trình nổi tiếng',

	'방송국 가기': 'Đến Đài truyền hình',
	'기록' : 'Lịch sử',
	'카테고리' : 'Danh mục',
	'추천받지 않기' : 'Không nhận gợi ý',
	'이어서 보기' : 'Xem tiếp',
	'최근 본 VOD' : 'VOD xem gần đây',
	'UP 한 VOD' : 'VOD đã UP',
	'나중에 보기 삭제' : 'Xóa mục Xem sau',
	'최근 본 VOD 삭제' : 'Xóa VOD xem gần đây',
	'UP 취소' : 'Hủy UP',
	'나중에 보기 목록에서 삭제 되었습니다.' : 'Đã xóa khỏi mục lục Xem sau.',
	'최근 본 VOD 목록에서 삭제 되었습니다.' : 'Đã xóa khỏi mục lục VOD xem gần đây.',
	'선택하신 VOD UP이 취소 되었습니다.' : 'Đã hủy UP VOD bạn chọn.',
	'추천 받지 않기가 반영되었습니다.' : 'Đã áp dụng Không nhận gợi ý.',
	'이미 즐겨찾기에 추가된 방송국 입니다.' : 'Đài truyền hình này đã có sẵn trong Yêu thích.',
	'이미 나중에 보기 등록된 VOD 입니다.' : 'VOD này đã có sẵn trong Xem sau.',
	'전체 삭제' : 'Xóa toàn bộ',
	'나중에 보기 한 VOD가 없습니다.' : 'Không có VOD trong Xem sau.',
	'최근 본 VOD가 없습니다.' : 'Không có VOD xem gần đây',
	'VOD 홈으로 이동하시면,<br>맞춤형 동영상을 추천 받으실 수 있습니다.' : 'Nếu tới Trang chủ VOD,<br>bạn có thể nhận được gợi ý về các video phù hợp.',
	'VOD 홈으로 이동' : 'Đến Trang chủ VOD',
	'구독' : 'Đăng ký theo dõi',
	'카테고리 선택' : 'Chọn hạng mục',
	'로그인 후 기록한 VOD를 보실 수 있습니다.' : 'Bạn có thể xem các VOD đã đánh dấu sau khi đăng nhập.',
	'로그인 후 즐겨찾기 한<br>BJ의 VOD를 보실 수 있습니다.' : 'Bạn có thể xem VOD của BJ<br>bạn yêu thích sau khi đăng nhập.',
	'나중에 보기 목록을 전체 삭제하시겠습니까?' : 'Bạn có muốn xóa toàn bộ mục lục Xem sau không?',
	'UP이 취소되었습니다.' : 'UP đã bị hủy.',
	'추천 받지 않기에 등록 되었습니다.' : 'Đã đăng ký không nhận gợi ý.',
	'즐겨찾기에서 삭제되었습니다.': 'Đã xóa khỏi Yêu thích.',
	'즐겨찾기 추가를 위해서는 로그인이 필요합니다.\n로그인 페이지로 이동하시겠습니까?' : 'Bạn cần phải đăng nhập để có thể thêm vào mục Yêu thích.\nBạn có muốn đến trang đăng nhập không?',
	'팬' : 'Người hâm mộ',
	'구독한 BJ가 없습니다.' : 'Không có BJ đăng ký.',
	'구독한 BJ의 VOD가 없습니다.' : 'Không có VOD của BJ đăng ký',
	'%s님을 즐겨찾기에서 삭제하시겠습니까?' : '%sBạn có muốn xóa khỏi Yêu thích không?',

	'TAG_VOD_LIST_COUNT' : '<span class="count">Tất cả <em class="point" text="@count"></em>Cái</span>',
	'TAG_VOD_FAVORITE_TITLE' : '<span class="bj_name_long">VOD của BJ thường kiếm </span><a class="bj_name bj_name_long" text="@nickname"></a>',
	'TAG_VOD_RECENT_TITLE' : '<span class="bj_name_long">BJ </span><a class="bj_name bj_name_long" text="@nickname"></a><span class="bj_name_long">thường kiếm</span>',
	'TAG_VOD_SUBSCRIBE_WHOSE_VOD' : '<button type="button" class="bj_name">VOD của<span text="@nickname"></span></button>',
	'TAG_VOD_SUBSCRIBE_LIST_COUNT' : '<span> <span text="@count"></span>Cái</span>',

	'알림': 'Thông báo',
	'받은 알림이 없습니다.<br><a ng-click="setting()"><span>알림 설정</span></a>에서 받고싶은 알림을 선택하세요.' : 'Không có thông báo đã nhận.<br>Vui lòng chọn thông báo bạn muốn nhận tại <a ng-click="setting()"><span>Cài đặt thông báo</span></a>.',
	'모두 읽음' : 'Đã đọc tất cả',
	'모두 삭제' : 'Xóa tất cả',
	'읽은 알림 삭제' : 'Xóa thông báo đã đọc',
	'알림 설정' : 'Cài đặt thông báo',
	'설정' : 'Cài đặt',
	'%d분 전' : '%dphút trước',
	'%d시간 전' : '%Dgiờ trước',
	'삭제' : 'Xóa',
	'알림이 삭제되었습니다.' : 'Đã xóa thông báo.',
	'숨기기' : 'Ẩn',
	'알림 콘텐츠 설정' : 'Cài đặt nội dung thông báo',
	'초기화' : 'Cài đặt lại',
	'저장' : 'Lưu',
	'푸시 설정' : 'Cài đặt thông báo Push',
	'기록 설정' : 'Cài đặt lịch sử',
	'푸시 알림은 <a ng-click="setup_link()"><span>AfreecaTV 앱 - 설정 - 알림 설정</span></a>에서 변경할 수 있습니다.' : 'Cài đặt Push có thể được thay đổi tại <a ng-click="setup_link()"><span>Ứng dụng AfreecaTV - Cài đặt - Cài đặt thông báo</span></a>.',
	'알림 설정을 초기화 합니다. \n변경했던 모든 세부 설정값을 초기 상태로 되돌립니다. 초기화한 알림 설정은 저장 시 적용됩니다.' : 'Khởi tạo cài đặt thông báo. \nKhôi phục tất cả các chi tiết cài đặt bạn đã thay đổi về tình trạng mặc định.<br>Cài đặt thông báo đã khởi tạo sẽ được áp dụng sau khi nhấn lưu.',
	'설정이 저장되었습니다.' : 'Cài đặt đã được lưu.',


	'발송취소' : 'Hủy gửi.',
	'해당 쪽지를 발송 취소하시겠습니까?' : 'Bạn có muốn dừng gửi thư này không?',
	'보관한 보낸 쪽지가 없습니다.' : 'Không có thư đã gửi được lưu trữ.',
	'보관한 받은 쪽지가 없습니다.' : 'Không có thư đã nhận được lưu trữ.',
	'읽지 않음' : 'Chưa đọc',
    '방송국에서 %s님이 애드벌룬 %s개를 선물 했습니다.' : 'Bạn %sđã tặng %squả bóng quảng cáo ở Đài truyền hình.',
    '유효기간 만료순' : 'Theo thời hết hạn',

    '추천' : 'Đề xuất',
    'VOD 추천 메뉴로 이동하시면,<br>맞춤형 VOD를 추천받을 수 있습니다.' : 'nếu bạn chuyển đến menu đề xuất VOD,<br>bạn có thể nhận được đề xuất VOD phù hợp',
    'VOD 추천받기' : 'Nhận đề xuất VOD',
	'로그인이 필요합니다.\n로그인 하시겠습니까?' : 'Bạn cần phải đăng nhập.\nBạn có muốn đăng nhập không?',
	'주소가 복사되었습니다.' : 'Địa chỉ đã được sao chép.',
	'본인은 즐겨찾기 할 수 없습니다.' : 'Bạn không thể thực hiện yêu thích.',

	'해당 쪽지를 신고하시겠습니까?\n(신고된 쪽지는 스팸함으로 이동됩니다.)' : 'Bạn có muốn báo cáo thư này không?\n(Thư bị báo cáo sẽ được chuyển về hòm thư rác.)',
	'해당 쪽지를 스팸 해제하시겠습니까?\n(스팸 해제된 쪽지는 받은 쪽지로 이동됩니다.)' : 'Bạn có muốn báo cáo thư này không phải thư rác không?\n(Thư không phải thư rác sẽ được chuyển về hòm thư đã nhận.)',
	'자동스팸분류': 'Phân loại thư rác tự động',
	'스팸해제' : 'Chống thư rác',
	'스팸신고': 'Báo cáo',
	'보관함': 'Lưu giữ',
	'스팸함': 'Thư rác',
	'스팸 쪽지가 없습니다.': 'Không có thư rác.',
	'스팸 쪽지': 'Thư rác',
	'스팸함 쪽지는 읽은 후 90일 뒤 자동으로 삭제됩니다.': 'Thư trong hòm thư rác sẽ được tự động xóa sau 90 ngày kể từ khi được đọc.',

	'"%s" 검색 결과' : '"%s" Kết quả tìm kiếm',
	'검색된 쪽지가 없습니다.' : 'Không có kết quả tìm kiếm.',
	'검색어를 입력해 주세요.' : 'Vui lòng nhập từ tìm kiếm.',
	'내용' : 'Nội dung',
	'더 알아보기' : 'Tìm hiểu thêm',
	'재생목록':'Danh sách phát',
	'재생목록에 담기':'Thêm vào danh sách phát',
	'재생목록 퍼가기':'Nhúng danh sách phát',
	'생성한 재생목록':'Tạo danh sách phát',
	'재생목록 보관함':'Thư viện danh sách phát',
	'재생목록이 없습니다.':'Không có danh sách phát.',
	'새 재생목록 만들기':'Tạo danh sách phát mới',
	'추가':'Thêm',
	'새 재생목록':'Danh sach phát mơi',
	'확인' : 'Xác nhận',
	'VOD 추가순': 'Theo bổ sung VOD',
	'오래된순': 'Theo cũ nhất',
	'수정하기': 'Sửa chữa',
	'삭제하기': 'Xóa bỏ',
	'업데이트' : 'Cập nhật',
	'공개': 'Công cộng',
	'비공개' : 'Riêng tư',
	'전체보기' : 'Xem tất cả',
	'목록 공개': 'Danh sách công bố',
	'목록 비공개': 'Danh sách riêng',
	'목록 퍼가기 허용': 'Cho phép trải rộng danh sách',
	'목록 퍼가기 비허용': 'Không cho phép nhúng danh sách',
	'제목을 입력해주세요.': 'Vui lòng nhập chủ đề.',
	'VOD가 없습니다.' : 'Không có VOD.',
	'재생목록을 삭제하시겠습니까?' : 'Bạn có muốn xóa danh sách phát?',
    '저화질' : 'Chất lượng thấp',
    '일반화질' : 'Chất lượng mặc định',
	'원본화질' : 'Chất lượng gốc',
	'앱에서 영상을 움직여 360˚로 감상하세요!' : 'Di chuyển video trong ứng dụng và xem ở 360 °!',
    '유료 광고 포함' : 'Bao gồm cả quảng cáo trả tiền',
    '참여를 위해<br>인코딩 중입니다.': 'Đang mã hóađể có thểxem.',
    '잠깐만 기다려주세요!': 'Vui lòng đợi trong giây lát!',
    '<span><em>초</em> 남았습니다.</span>': '<span>Còn lại <em>seconds</em></span>',
    '보유 중인 선물 받은 구독권' : 'Sở hữu vé đăng ký xuất trình',
    '보유 중인 구독 선물권' : 'Có phiếu quà tặng đăng ký',
    '아이템 정보' : 'Thông tin vật phẩm',
    '광고 Skip, Full방 입장, 고화질 방송참여가 가능한<br>퀵뷰아이템을 만나보세요.' : 'Bỏ qua quảng cáo, vào toàn phòng,<br> đáp ứng mục xem nhanh <br>  cho phép phát sóng độ nét cao.',
    '선물/후원 정보' : 'Thông tin quà tặng / tài trợ',
	'교환' : 'Đổi',

	'사용하러 바로가기':'Đi đến sử dụng',
	'구독 구매하기':'Đăng ký để mua',
	'구매하러 바로가기':'Đi đến mua hàng',
    '연속선물' : 'Tự động +1',
    '연속선물이란?' : 'Tự động +1?',
    '연속선물 ON 상태로 선물하면 선물 창이 유지되며<br>선물할 개수는 선물 완료 후 +1개로 자동 입력됩니다.' : 'Nếu bạn trình bày với quà tặng liên tục BẬT,  <br> cửa sổ quà tặng sẽ được duy trì và số lượng quà tặng <br> sẽ tự động được nhập là +1 sau khi quà tặng hoàn tất.',
	'구매한 스티커' : 'Mua nhãn dán',
	'선물받은 스티커' : 'Nhãn dán quà tặng',
	'스티커 아이템' : 'Vật phẩm nhãn dán',
	'스티커 교환하기' : 'Trao đổi nhãn dán',
	'아이템 정보' : 'Thông tin vật phẩm',

	'퀵뷰 플러스를 이용 중에는 등록이 불가능 합니다.' : 'Bạn không thể đăng ký khi đang sử dụng Xem nhanh+',
	'퀵뷰를 이용 중에는 등록이 불가능 합니다.' : 'Bạn không thể đăng ký khi đang sử dụng Xem nhanh',
	'플러스로 전환' : 'Chuyển sang<br>Plus',
	'유료 퀵뷰, 유료 퀵뷰 선물권만 \n퀵뷰 플러스로 전환 가능합니다.' : 'Chỉ có thể chuyển đổi chế độ xem nhanh có trả phí và xem nhanh\ncó trả phí sang chế độ xem nhanh+.',
	'퀵뷰가 사용되었습니다.\n퀵뷰 플러스로 전환 기회가 있습니다.\n퀵뷰 플러스로 전환하시겠습니까?' : 'Xem nhanh đã được sử dụng.\nCơ hội để bạn chuyển sang Xem nhanh+.\n Bạn có muốn chuyển sang chế độ xem nhanh+ không?',
	"무료 퀵뷰가 사용되었습니다.<br>무료 퀵뷰는 퀵뷰 플러스로 전환이 불가합니다.": "Bạn đã sử dụng xem nhanh miễn phí.<br>Không thể chuyển chế độ xem nhanh miễn phí sang chế độ xem nhanh+.",
	"무료 퀵뷰 플러스가 사용 되었습니다.<br>무료 퀵뷰 플러스는 애드벌룬이 모이지 않습니다.": "Xen nhanh+ miễn phí đã được sử dụng",
	"퀵뷰가 사용되었습니다.": "Tôi đã sử dụng chế độ xem nhanh.",
	"퀵뷰 플러스가 사용되었습니다.": "Tôi đã sử dụng chế độ xem nhanh+.",
	'<strong>퀵뷰 플러스로</strong><br>전환 기회!' : 'Cơ hội chuyển<br><strong>sang Xem nhanh+</strong>',
	'퀵뷰 이용권을 퀵뷰 플러스로 전환해보세요.' : 'Bạn có thể chuyển vé Xem nhanh sang Xem nhanh +.',
	'이용기간' : 'Thời gian sử dụng',
	'전환 시점부터 이용기간이 재 산정되어 이용기간이 늘어납니다.' : 'Thời gian sử dụng sẽ được tính lại từ thời điểm chuyển đổi và thời gian sử dụng sẽ được gia hạn.',
	'퀵뷰 플러스의 더 커진 혜택' : 'Xem nhanh+ lợi ích nâng cấp',
	'VOD 광고 NO!' : 'không quảng cáo VOD!',
	'FULL방 입장' : 'Vào đầy đủ phòng',
	'방송 광고 NO!' : 'Phát quảng cáo KHÔNG!',
	'멀티뷰' : 'Nhiều góc nhìn',
	'방송 미리보기(모바일)' : 'Xem trước phát sóng (Mobile)',
	'퀵뷰 사용 기간 별 전환 금액' : 'Số tiền chuyển đổi chế độ xem nhanh theo thời gian sử dụng',
	'사용 기간에 따른 전환 금액 지불 시 이용기간이 재 산정되어 <strong>이용기간이 늘어나는 혜택</strong>을 제공합니다!' : 'Khi thanh toán số tiền chuyển đổi tùy thuộc vào thời gian sử dụng, thời gian sử dụng sẽ được tính lại và <strong>sẽ được cung cấp quyền lợi khi kéo dài thời gian sử dụng!</strong>',
	'사용' : 'sử dụng',
	'1,000원' : '$ 1.00 USD',
	'2,000원' : '$ 2.00 USD',
	'2,500원' : '$ 2.50 USD',
	'5,000원' : '$ 5.00 USD',
	'10,000원' : '$ 10.00 USD',
	'20,000원' : '$ 20.00 USD',
	'플러스로 전환하기' : 'Chuyển sang Plus',
	'기존 퀵뷰로 사용하기' : 'Sử dụng Xem nhanh hiện tại',
	'※부가세 미포함' : '※Không bao gồm VAT',
	'사용기간 <strong>%s일</strong>로 <span>%s</span> 결제 시 <strong>퀵뷰 플러스 %s일권</strong>으로 전환됩니다.' : 'Bạn thanh toán bằng <span>%s</span> trong %s ngày,số tiền đó sẽ được chuyển thành<strong>Vé %s ngày Xem nhanh+.</strong>',
	'혜택이 더 커진 퀵뷰플러스!<br>간편하게 전환해보세요.' : 'Xem nhanh+ với những lợi ích lớn hơn! Hãy thử chuyển đổi dễ dàng!',
	'선물 ・ 후원 정보' : 'Thông tin quà tặng ・ tài trợ',
	'무료체험중' : 'Dùng thử miễn phí',
	'(iOS자동결제)' : '(thanh toán tự động trên iOS)',
	'애플 정기 구독(자동결제)으로\n현재 디바이스에선\n결제수단 변경이 불가합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Bạn không thể thay đổi phương thức thanh toán\ntrên thiết bị hiện tại của mình\n bằng đăng ký dài hạn của Apple (thanh toán tự động).\nSử dụng iPhone (hoặc iPad) App Store > Apple ID > Đăng ký.',
	'애플 정기구독(자동결제)으로\n현재 디바이스에선\n해지가 불가능 합니다.\n\n아이폰(혹은 아이패드)의\nApp Store>Apple ID>구독을 이용해주세요.' : 'Không thể hủy đăng ký\ndài hạn của Apple (thanh toán tự động)\ntrên các thiết bị hiện tại.Sử dụng iPhone (hoặc iPad) App Store > Apple ID > Đăng ký.',
	'해지 신청중' : 'Đang hủy',
	'2개의 방송을 동시에! 모바일 멀티뷰' : 'Hai phát sóng cùng một lúc! nhiều chế độ xem trên thiết bị mobile',

	'내가 보유한 스티커' : 'Sở hữu nhãn dán của tôi',
	'100개 이상의 시그니처 풍선 선물시 클립 남기기' : 'Để lại clip với hơn 100 phần quà khinh khí cầu đặc sắc',
	'소개글' : 'Giới thiệu',
	'공지' : 'Thông báo',
	'나이' : 'Tuổi',
	'경력/수상' : 'Sự nghiệp / Giải thưởng',
	'수상 메달' : 'Trao huy chương',
	'앗 혹시 찾으시는 분이...' : "Có lẽ bạn đang tìm kiếm ...",
	'지금 이 콘텐츠에 주목!' : 'Hãy chú ý đến nội dung này ngay bây giờ!',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 콘텐츠</span>' : '<span class="normal">Nội dung được đề xuất cho những người dùng đã tìm kiếm</span> \'{{ query }}\'',
	'누적 참여자' : 'Người xem tích lũy',
	'방송 시간' : 'Thời gian phát',

	'쉬는시간 후 방송이 이어집니다.' : 'Sau khi giải lao thời gian, sóng phát sẽ tiếp tục.',
	'BJ에게 후원이 되는 광고 보면서 조금만 기다려요!' : 'Các bạn hãy đợi khi xem quảng cáo để hỗ trợ các BJ nhé!',
	
	'URL 복사' : 'chép URL',
	'구독하러 바로가기':'Đi đến mua hàng',

	'빠른 답장' : 'Trả lời nhan',
	'기본 메시지로 저장' : 'Lưu vào tin nhắn cơ bản',
	'보내기' : 'Trả lời',
	'후원 쪽지' : 'Tin nhắn hỗ trợ',
	
	'선물 메세지' : 'Tin nhắn quà tặng',
	'목소리 선택' : 'Lựa chọn giọng nói',
	'알림음 목소리를 사용할 수 있도록<br>BJ에게 스티커를 선물해보세요!' : 'Vui lòng đưa nhãn dán cho BJ<br>để bạn có thể sử dụng âm thanh báo!',
	'BJ의 방송 설정에 전자비서가 없거나 별도의 개수 설정이 되어 있을 경우 알림음 재생이 안될 수 있습니다.' : 'Không có thư ký điện tử trong cài đặt phát sóng BJ hoặc số được đặt riêng thìâm thanh báo sẽ không thể phát được.',

	'영상 광고 없는 VOD 재생!' : 'Phát lại video mà không có quảng cáo VOD!',
	'방송 입장 영상광고 NO!' : 'Phát quảng cáo video tuyển sinh NO!',
	'배너형, 로딩광고, LIVE 중간광고 제외' : 'Loại biểu ngữ, tải quảng cáo, loại trừ quảng cáo LIVE trung gian',

	'청소년 유저들을 보호하기 위해 <strong>19세 이상의 유저만</strong><br>이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.<br>아프리카TV 앱에서만 참여할 수 있습니다.':'Để bảo vệ người dùng thanh thiếu niên<br> nội dung này được đặt ở giới hạn độ tuổi<br> để chỉ những<strong> người dùng trên 19 tuổi</strong> mới có thể sử dụng.<br>Bạn chỉ có thể tham gia vào ứng dụng AfreecaTV.',
	'청소년 유저들을 보호하기 위해 19세 이상의 유저만 이용할 수 있도록 연령제한이 설정된 컨텐츠입니다.\n아프리카TV 앱에서만 참여할 수 있습니다.\n\n아프리카TV 앱에서 참여하시겠습니까?':'Để bảo vệ người dùng thanh thiếu niên nội dung này được đặt ở giới hạn độ tuổi\nđể chỉ những người dùng trên 19 tuổi mới có thể sử dụng.\n\nBạn có muốn tham gia ứng dụng AfreecaTV không?',	

	'대결미션' : 'Nhiệm vụ là gì', 
	'대결미션이란?': 'Nhiệm vụ đối đầu là gì?',
	'대결단계에서만 후원 가능합니다.': 'Chỉ có thể hỗ trợ ở nhiệm vụ đối đầu.',
	'보유 별풍선이 즉시 차감됩니다.': 'Ngay lập tức bị trừ vào bóng sao còn lại của bạn.',
	'BJ가 팀을 이루어 대결 콘텐츠를 진행하고 후원된 별풍선을 대결 결과에 따라 나눠가지는 기능입니다.': 'Là chức năng mà các BJ lập nhóm để tiến hành một nội dung thi đấu và phân phát các bóng sao được tặng theo kết quả của cuộc thi.',
	'현재 방송 BJ에게 별풍선 후원하는 것이 아닌 <strong>대결미션에 별풍선 후원하여 별풍선을 모금합니다.</strong>': 'Bạn sẽ không quyên góp cho BJ, nhưng quyên góp cho nhiệm vụ chiến đấu.',
	'별풍선 후원 시 해당 방송의 팬클럽 가입과 열혈팬 순위에 집계됩니다.': 'Các khoản đóng góp của bóng sao sẽ đảm bảo bạn tham gia câu lạc bộ fan và số lượng VIP.',
	
	'도전미션' : 'Nhiệm vụ thử thách',
	'보유 별풍선 :' : 'sở hữu :',
	'도전미션이란?' : 'thử thách là gì?',
	'BJ에게 도전미션을 요청하고 <br>별풍선 상금을 후원해 보세요!' : 'Yêu cầu một nhiệm vụ thử thách',
	'새로운 미션 요청' : 'yêu cầu nhiệm vụ',
	'후원할 별풍선' : 'Hỗ trợ bóng sao',
	'후원하기' : 'Hỗ trợ',
	'미션 내용을 입력해 주세요. (최대 30자)' : 'Vui lòng nhập chi tiết nhiệm vụ. (tối đa 30 ký tự)',
	'미션 제한 시간' : 'thời gian giới hạn',
	'도전미션 일': 'ngày',
	'도전미션 시간': 'giờ',
	'도전미션 분' : 'phút',
	'미션 상금' : 'Tiền thưởng nhiệm vụ',
	'미션 요청' : 'lời yêu cầu',
	'BJ가 미션을 진행, 성공 여부에 따라 유저가 모금한 상금을 획득하는 후원 서비스입니다.' : 'Đây là một dịch vụ hỗ trợ trong đó BJ tiến hành các nhiệm vụ và nhận phần thưởng do người dùng tặng tùy thuộc vào việc họ có thành công hay không.',
	'도전미션은 유저가 미션 요청하거나 BJ가 직접 미션을 등록할 수 있습니다.' : 'Các nhiệm vụ thử thách có thể được người dùng yêu cầu hoặc đăng ký trực tiếp bởi BJ.',
	'미션 결과는 BJ가 결정하며 미션 요청한 유저는 ‘성공’만 결정 가능합니다.' : 'Kết quả nhiệm vụ được xác định bởi BJ và người dùng đã yêu cầu nhiệm vụ chỉ có thể xác định "thành công".',
	'도전미션은 BJ와 유저의 신뢰를 전제하에 진행되기에 어떤 상황에도 미션 결과를 번복할 수 없습니다.' : 'Nhiệm vụ thử thách dựa trên sự tin tưởng của BJ và người dùng, vì vậy kết quả nhiệm vụ không thể bị đảo lộn trong bất kỳ trường hợp nào.',
	'미션 제한시간이 경과할 경우 자동으로 미션 실패됩니다.' : 'Nếu quá thời hạn nhiệm vụ, nhiệm vụ sẽ tự động thất bại.',
	'미션 성공 시 BJ에게 모금된 상금이 전달됩니다.' : 'Khi nhiệm vụ thành công, tiền thưởng tặng cho BJ sẽ được chuyển giao.',
	'미션 취소, 미션 실패 시 상금은 유저에게 반환됩니다.' : 'Nếu nhiệm vụ bị hủy hoặc nhiệm vụ thất bại, tiền thưởng sẽ được trả lại cho người dùng.',
	'BJ는 미션 제한시간을 1회 연장할 수 있습니다. (최대 10일)' : 'BJ có thể kéo dài thời hạn nhiệm vụ một lần. (tối đa 10 ngày)',
	'대기/진행 중인 미션에 상금을 추가 후원할 수 있습니다.' : 'Bạn có thể thêm tiền thưởng cho các nhiệm vụ đang chờ/đang diễn ra của mình.',
	'팬클럽 가입/열혈팬 집계는 미션 성공 후 상금이 BJ에게 전달되는 시점에 이루어집니다.' : 'Tham gia câu lạc bộ người hâm mộ/Đếm người hâm mộ châu Phi sẽ được thực hiện khi tiền thưởng được trao cho BJ sau khi nhiệm vụ thành công.',
	'유저는 요청한 미션이 최근 24시간 내 누적 5회 취소될 경우 24시간 동안 모든 BJ에게 미션 요청이 불가합니다. 신중히 미션을 요청해 주세요.' : 'Người dùng không thể yêu cầu nhiệm vụ tới BJ trong 24 giờ nếu nhiệm vụ được yêu cầu đã bị hủy 5 lần trong 1 ngày qua. Yêu cầu nhiệm vụ cẩn thận.',
	
	'아이템은 여기서 구매하세요!' : 'Mua vật phẩm tại đây!',
	'1:1 문의 바로가기' : 'Đi tới Hỏi 1: 1',
	'내 애드벌룬 확인하고 선물하기' : 'Kiểm tra bóng quảng cáo của tôi và quà tặng',

	'규칙에 동의합니다!' : 'Tôi đồng ý với các quy tắc!',
	
	'내 별풍선' : 'Hỗ trợ',
	'내 별풍선ㆍ아이템' : 'Hỗ trợ · Vật phẩm',
	'드롭스 이벤트 진행 중' : 'Sự kiện đang Drops',

	'퀵뷰': 'Xem nhanh',
	'퀵뷰 플러스': 'Xem nhanh+',
	'만료': 'Hết hạn',
	'예약': 'đặt trước',
	'사용 예약': 'Đặt',
	'예약 취소': 'Hủy bỏ',
	'%s 사용을 예약 하였습니다.\n\n현재 사용중인 퀵뷰 아이템의 사용기간이 만료되면, 예약한 아이템을 이어서 사용합니다.': 'Bạn đã đặt trước sử dụng %s.\n\nSau khi vật phẩm xem nhanh hiện tại của bạn hết hạn, bạn sẽ tiếp tục sử dụng mục đó như đã lên lịch.',
	'%s 사용을 예약 취소하시겠습니까?': 'Bạn có muốn hủy %s đã lên lịch không?',
	'%s 사용 예약이 취소되었습니다.\n\n사용 예약을 원하실 경우 \'사용 예약\'을 눌러주세요.': 'Đã hủy %s đã lên lịch.\n\nNếu bạn muốn lên lịch sử dụng xem nhanh, vui lòng nhấp vào \'Lên lịch\'.',

	'공식방송국':	'Blog chính',
	'공식 방송국':'Blog chính',
	'관련BJ':	'Liên BJ',
	'관련 BJ':	'Liên BJ',
	'이벤트':	'Sự kiện',
	'시리즈게임':	'Loạt game',
	'시리즈 게임':	'Loạt game',
	'전체':	'Toàn bộ',
	'명':	'người',
	'관련BJ<br>더보기':	'BJ liên quan<br>Xem thêm',
	'LIVE':	'LIVE',
	'다시보기':	'Phát lại',
	'경기없음':	'Không tiếp',
	'경기취소':	'Bị hủy',
	'경기예정':	'Lịch',
	'결과<br>보기':	'Kết quả<br>Xem',
	'참여인원순':	'Theo tham gia',
	'시청인원순':	'Theo người xem',
	'최근방송':	'Phát sóng mới nhất',
	'LIVE 참여하기':	'Tham gia LIVE',
	'최근 방송한 BJ가 없습니다.<br>해당 카테고리로 방송을 시작해 보세요!':	'Không có phát sóng BJ nào gần đây. <br> Hãy thử phát trực tiếp trong danh mục này! ',
	'예정된 경기가 없습니다.':	'Không có trận đấu theo lịch trình nào.',
	'경기종료':	'Đã kết thúc',
	'최신방송순':	'Theo mới nhất',
	'\'{{ query }}\' <span class="normal">검색한 유저를 위한 추천 BJ</span>' : '<span class="normal">BJ được đề xuất cho những người dùng đã tìm kiếm</span> \'{{ query }}\'',
	'계정연결': 'Link Account',
	'연결된 계정을 확인할 수 있습니다.': ' ',
	'남자': 'Nam',
	'여자': 'Nữ',
	'캐릭터': 'Tính cách',
};

